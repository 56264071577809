import zrender from "zrender";
import { log } from "util";

// 老子重新造的轮子
const mapper = {
    1: "一",
    2: "二",
    3: "三",
    4: "四",
    5: "五",
    6: "六",
    7: "七",
    8: "八",
    9: "九",
    10: "十",
    11: "十一",
    12: "十二",
    13: "十三",
    14: "十四",
    15:"十五",
    16:"十六",
    17:"十七",
    18:"十八",
    19:"十九",
    20:"二十"
}

// 多种样式的选择

const style_mapper = {
    1: { rec_style: { fill: "transparent", stroke: "green" }, text_style: { textFill: "red" }, text_align: "right-top" },   // 大题样式1
    2: { rec_style: { opacity: 0.2, fill: "#F0FFE6", stroke: "#ccc" }, text_style: { textFill: "#ccc" }, text_align: "right-top" },  //大题样式2
    3: { rec_style: { fill: "transparent", stroke: "red" }, text_style: { textFill: "red" }, text_align: "right-top" },
    4: { rec_style: { opacity: 0.2, fill: "#FFFDF3", stroke: "#ccc" }, text_style: { textFill: "#ccc" }, text_align: "right-top" }, //小题样式1
}

class ResizableRectangle extends zrender.Group {
    constructor(opts, extra_info, click_handler, resize_handler) {
        // opts 里面有两个字段：
        // shape : {x,y,width,height}
        // scale : 0.4
        // 其中shape表示的是最最原始的坐标，指在原始图片上的坐标，而scale表示缩放
        // 所有的显示的坐标都必须经过scale计算得到

        // extra_info里面存放的是其他的信息，比如大题题号，小题题号，类型，等等等等

        // 当要改变坐标的时候，有两种该法：一种是scale不变，该x，y，width，height；一种是x，y，width，height不变，改scale
        super();
        this.init_all(opts, extra_info, click_handler, resize_handler)
    }
    init_all(opts, extra_info, click_handler, resize_handler) {
        opts = this.handle_error_shape(opts)
        opts = this.init_text(opts,extra_info)
        const { shape: { x, y, width, height }, scale, resizable, moveable, fuckable, disable } = opts
        const new_x = parseInt(x * scale), new_y = parseInt(y * scale), new_w = parseInt(width * scale), new_h = parseInt(height * scale);
        this.position[0] = new_x;
        this.position[1] = new_y;
        this.extra_info = extra_info;
        this.opts = opts
        this.main_rect = new zrender.Rect({
            ...opts, shape: { x: 0, y: 0, width: new_w, height: new_h },
        });
        if (fuckable) {
            const line1 = new zrender.Line({ shape: { x1: 0, y1: 0, x2: 0, y2: 0 }, style: { stroke: "red" } })
            const line2 = new zrender.Line({ shape: { x1: new_w, y1: 0, x2: new_w, y2: 0 }, style: { stroke: "red" } })
            const line3 = new zrender.Line({ shape: { x1: new_w, y1: new_h, x2: new_w, y2: new_h }, style: { stroke: "red" } })
            const line4 = new zrender.Line({ shape: { x1: 0, y1: new_h, x2: 0, y2: new_h }, style: { stroke: "red" } })
            this.add(line1)
            this.add(line2)
            this.add(line3)
            this.add(line4)
            const self = this
            line1.animate("shape", false).when(100, { x2: new_w }).start().done(() => {
                line2.animate("shape", false).when(100, { y2: new_h }).start().done(() => {
                    line3.animate("shape", false).when(100, { x1: 0 }).start().done(() => {
                        line4.animate("shape", false).when(100, { y1: 0 }).start().done(() => {
                            self.add(self.main_rect)
                            self.remove(line1)
                            self.remove(line2)
                            self.remove(line3)
                            self.remove(line4)
                        })
                    })
                })
            })
        } else {
            this.add(this.main_rect);
        }

        
        this.resize_handler = resize_handler
        this.click_handler = click_handler
        this.show_four = true;
        if (!disable) {  // 如果disable了，那么不可移动，不可改变大小，不会出现四角的鬼东西
            this.on("click", () => {
                for (let i = 0; i < this.parent.children().length; i++) {
                    let tmp = this.parent.children()[i];
                    if (tmp.hide_four_cornors) {
                        tmp.hide_four_cornors();
                    }
                    if(tmp.to_original_style){
                        tmp.to_original_style();
                    }
                }
                click_handler(this);
                if (resizable) {
                    this.main_rect.attr("style", { stroke: "#ff00e5" })
                    this.show_four_cornors();
                }
                this.parent.__zr.flush();
            });
            const left_up = new zrender.Circle({
                shape: { cx: 0, cy: 0, r: 3 },
                style: { fill: "white", stroke: "black" },
                cursor: "nw-resize",
            });
            const right_up = new zrender.Circle({
                shape: { cx: new_w, cy: 0, r: 3 },
                style: { fill: "white", stroke: "black" },
                cursor: "ne-resize",
            });
            const left_bottom = new zrender.Circle({
                shape: { cx: 0, cy: new_h, r: 3 },
                style: { fill: "white", stroke: "black" },
                cursor: "sw-resize",
            });
            const right_bottom = new zrender.Circle({
                shape: { cx: new_w, cy: new_h, r: 3 },
                style: { fill: "white", stroke: "black" },
                cursor: "se-resize",
            });
            this.four_cornors = [left_up, right_up, left_bottom, right_bottom];
            this.init_four_cornors(opts);
            if (moveable && !disable) {
                this.init_move_event()
            }
        }
    }

    handle_error_shape(opts) {
        // 如果opts中shape的width或者height出现负数，那么修正这种情况
        const { x, y, width, height } = opts.shape;
        let n_x, n_y, n_w, n_h;
        if (width < 0) {
            n_x = x + width
            n_w = -width
        } else {
            n_x = x, n_w = width
        }
        if (height < 0) {
            n_y = y + height,
                n_h = -height
        } else {
            n_y = y, n_h = height
        }
        return { ...opts, shape: { x: n_x, y: n_y, width: n_w, height: n_h } }
    }

    init_text(opts,extra_info) {
        const new_style = this.get_original_text(extra_info)
        return {...opts,style:{...opts.style,...new_style}}
    }

    get_original_text(extra_info){
        let new_style = {}
        if (extra_info.level == 1) {
            new_style = {
                text: extra_info.dati_num? "第" + mapper[extra_info.dati_num] + "大题":"",
                textFill:'red',
                textPosition: 'top', 
                textDistance:0
            }
        } else if (extra_info.level == 2) {
            new_style = {
                text: extra_info.dati_num && extra_info.xiaoti_num?extra_info.dati_num + "." + extra_info.xiaoti_num:"",textFill: "red",
                textPosition: [5,5],    
                fontSize:18,
                textShadowColor:'red'
            }
        } else if (extra_info.level == 3) {
            new_style = {
                text: extra_info.dati_num && extra_info.xiaoti_num && extra_info.third_xiaoti_num?extra_info.dati_num + "." + extra_info.xiaoti_num+ "." + extra_info.third_xiaoti_num:"",textFill: "red",
                textPosition: [5,5],    
                fontSize:18,
                textShadowColor:'red'
            }
        } else if (extra_info.level == 100) {
            let inner_text = undefined
            if (extra_info.answer_text){
                inner_text =  extra_info.answer_index+"_"+extra_info.answer_text
            }else{
                inner_text =  extra_info.answer_index
            }
            new_style = {
                text: inner_text,
                fontSize:18,
                textAlign: "center",textFill: "red",
                textStroke:"red",
                textShadowColor:'red',
                fontWeight:"800"
            }
        } else if (extra_info.level == 51) {
            new_style = {
                text: '学号',
                textAlign: "center",textFill: "red"
            }
        } else if (extra_info.level == 53) {
            new_style = {
                text: '条形码学号',
                textAlign: "center",textFill: "red"
            }
        } else if (extra_info.level == 54) {
            new_style = {
                text: '二维码学号',
                textAlign: "center",textFill: "red"
            }
        } else if (extra_info.level == 55) {
            new_style = {
                text: '学号姓名遮罩',
                textAlign: "center",textFill: "red"
            }
        }else if(extra_info.level == 609){
            new_style = {
                text: '姓名',
                textAlign: "center",textFill: "red"
            }
        }else if (extra_info.level == 61) {
            new_style = {
                text: '总成绩',
                textAlign: "center",textFill: "red"
            }
        } else if (extra_info.level == 62) {
            new_style = {
                text: '缺考',
                textAlign: "center",textFill: "red"
            }
        } else if (extra_info.level == 633) {
            new_style = {
                text: '卷面分',
                textAlign: "center",textFill: "red"
            }
        } else if(extra_info.level == 101){
            new_style = {
                text:extra_info.tuka_index,
                // textAlign: "center",
                textFill: "black",
                fontSize: 20,
                fontWeight:"bold",
                textPosition: 'left'
            }
        }
        return new_style
    }

    refresh_text(){
        const new_style = this.get_original_text(this.extra_info)
        this.main_rect.attr("style",new_style)
    }

    copy() {
        let { x, y, width, height } = this.opts.shape
        const new_opts = { ...this.opts, shape: { x: x + 20, y: y + 20, width, height } }
        return new ResizableRectangle(new_opts, { ...this.extra_info }, this.click_handler, this.resize_handler)
    }

    init_move_event() {
        const self = this;
        self.main_rect.on("mousedown", (e) => {
            const x1 = e.event.clientX;
            const y1 = e.event.clientY;
            let { x, y, width, height } = this.opts.shape, scale = this.opts.scale;
            self.parent.__zr.on("mousemove", (e) => {
                const delta_x = parseInt((e.event.clientX - x1) / scale);
                const delta_y = parseInt((e.event.clientY - y1) / scale);
                self.refresh_coords(
                    {
                        x: x + delta_x,
                        y: y + delta_y,
                        width: width,
                        height: height,
                    }
                );
            });
            self.parent.__zr.on("mouseup", (e) => {
                self.parent.__zr.off("mousemove");
                self.parent.__zr.off("mouseup");
            });
        });
    }

    refresh_scale(new_scale) {
        // 刷新缩放，坐标不变
        this.opts.scale = new_scale
        this.static_refresh()
    }
    static_refresh() {
        let { shape: { x, y, width, height }, scale } = this.opts
        let nx = parseInt(x * scale), ny = parseInt(y * scale), nwidth = parseInt(width * scale), nheight = parseInt(height * scale);
        this.main_rect.attr({ shape: { width: nwidth, height: nheight },style:{truncate:{outerWidth:nwidth}} });
        this.attr("position", [nx, ny]);
        if(this.four_cornors && this.four_cornors.length > 0){
            this.four_cornors[1].attr({ shape: { cx: nwidth } });
            this.four_cornors[2].attr({ shape: { cy: nheight } });
            this.four_cornors[3].attr({ shape: { cx: nwidth, cy: nheight } });
        }
    }

    refresh_coords(shape) {
        // 刷新坐标，缩放不变
        const {x,y,width,height} = shape;
        const [w,h] = this.extra_info.bounds
        if( x < 10 || y < 10 || x+ width > w-10 || y + height > h-10){
            return
        }
        this.opts.shape = { ...this.opts.shape, ...shape }
        this.opts = this.handle_error_shape(this.opts)
        this.static_refresh()
        this.resize_handler(this.extra_info, this.opts.shape)
    }

    init_four_cornors() {
        const self = this;
        let opts = self.opts;
        this.four_cornors[0].on("mousedown", (e) => {
            const x1 = e.event.clientX;
            const y1 = e.event.clientY;
            let { x, y, width, height } = this.opts.shape, scale = this.opts.scale;
            self.parent.__zr.on("mousemove", (e) => {
                const delta_x = parseInt((e.event.clientX - x1) / scale);
                const delta_y = parseInt((e.event.clientY - y1) / scale);
                self.refresh_coords(
                    {
                        x: x + delta_x,
                        y: y + delta_y,
                        width: width - delta_x,
                        height: height - delta_y,
                    }
                );
            });
            self.parent.__zr.on("mouseup", (e) => {
                self.parent.__zr.off("mousemove");
                self.parent.__zr.off("mouseup");
            });
        });

        this.four_cornors[3].on("mousedown", (e) => {
            const x1 = e.event.clientX;
            const y1 = e.event.clientY;
            let { x, y, width, height } = this.opts.shape, scale = this.opts.scale;
            console.log("click me bottom right")
            self.parent.__zr.on("mousemove", (e) => {
                const delta_x = parseInt((e.event.clientX - x1) / scale);
                const delta_y = parseInt((e.event.clientY - y1) / scale);
                self.refresh_coords({
                    x, y,
                    width: width + delta_x,
                    height: height + delta_y,
                })
            });
            self.parent.__zr.on("mouseup", (e) => {
                self.parent.__zr.off("mousemove");
                self.parent.__zr.off("mouseup");
            });
        });
    }

    show_four_cornors() {
        this.four_cornors.forEach((item) => {
            this.add(item);
        });
    }

    hide_four_cornors() {
        if(this.four_cornors ==null || this.four_cornors == undefined || this.four_cornors.length !=4){
            return
        }
        this.four_cornors.forEach((item) => {
            this.remove(item);
        });
    }

    to_original_style(){
        this.main_rect.attr("style",this.opts.style)
    }
}



class MoveableLine extends zrender.Group {
    constructor(opts, click_handler, move_handler) {
        super();
        this.main_line = new zrender.Line({
            shape: { x1: 10, y1: 0, x2: 10, y2: 0 },
            style: {
                stroke: "red"
            }
        });
        this.main_line.animate("shape", false).when(200, { y2: opts.height * opts.scale }).start()
        this.add(this.main_line)
        this.extra_info = { level: 41 }
        this.refresh_position(opts)
        this.click_handler = click_handler
        if (!opts.disable) {
            this.init_event()
        }
        this.move_handler = move_handler
    }
    refresh_position(opts) {
        const { shape: { x }, scale } = opts
        this.opts = opts
        const new_x = parseInt(x * scale)
        this.attr("position", [new_x - 10, 0])
    }
    refresh_scale(scale) {
        const { shape: { x } } = this.opts
        this.opts = { ...this.opts, scale }
        const new_x = parseInt(x * scale)
        this.attr("position", [new_x - 10, 0])
        this.main_line.attr("shape", { y2: this.opts.height * scale })

    }
    init_event() {
        const self = this
        self.on("click", () => {
            self.click_handler(self)
        })
        self.on("mousedown", e => {
            const x = e.event.clientX;
            const old_x = self.opts.shape.x, scale = self.opts.scale
            self.parent.__zr.on("mousemove", e => {
                const delta_x = parseInt((e.event.clientX - x) / scale);
                self.refresh_position({ ...self.opts, shape: { x: old_x + delta_x } })
                self.move_handler(self)
            })
            self.parent.__zr.on("mouseup", e => {
                self.parent.__zr.off("mousemove");
                self.parent.__zr.off("mouseup");
            })
        })
    }
}








export { ResizableRectangle, MoveableLine }