import { ResizableRectangle, MoveableLine } from "../components/rectangle.js";
export default {
    addchengjikuang: function () {
        this.add_level = 61
        // this.clear_except_background()
        this.init_draw_relative_event();
    },
    addquekao: function () {
        this.add_level = 62
        this.current_index = 0
        this.initBackground()
        this.show_all_other_flags();
        // this.clear_except_background()
        this.init_draw_relative_event();
    },

    refresh_other_flag_status() {
        let extra_info = this.data.fucker.extra_info
        this.other_flag_status = {
            chengjikuang: extra_info.chengjikuang && extra_info.chengjikuang.position,
            quekao: extra_info.quekao && extra_info.quekao.position,
            paperScore: extra_info.paperScore ,
        }
    },

    deletequekao() {
        delete this.data.fucker.extra_info.quekao
        this.show_all_other_flags()
    },
    show_all_other_flags(not_clear) {
        if (!not_clear) {
            this.clear_except_background()
        }
        // if (this.current_index != 0) {
        //     return
        // }
        this.refresh_other_flag_status()
        if (this.other_flag_status.quekao && this.current_index == 0) {
            const position = this.data.fucker.extra_info.quekao.position
            this.group.add(this.generate_rectangle({ position, resizable: true, moveable: true },
                { level: 62 }))
        }
        if (this.other_flag_status.chengjikuang && this.current_index == 0) {
            const position = this.data.fucker.extra_info.chengjikuang.position
            this.group.add(this.generate_rectangle({ position, resizable: true, moveable: true },
                { level: 61 }))
        }
        if (this.other_flag_status.paperScore) {
            const {position,file_index} = this.data.fucker.extra_info.paperScore
            if(file_index == this.current_index){
                this.group.add(this.generate_rectangle({ position, resizable: true, moveable: true },
                    { level: 633 }))
            }
        }


    },
    changeLines(a) {
        const aaa = a.split(".")
        this.current_index = parseInt(aaa[0])
        const line_size = parseInt(aaa[1])
        let lines = undefined
        if (line_size == 0) {
            lines = []
        } else if (line_size == 1) {
            lines = [200]
        } else {
            lines = [200, 400]
        }
        this.data.image_infos[this.current_index].lines = lines
        this.initBackground()
        this.show_lines();
    },
    show_lines: function (not_clear) {
        //显示分栏线
        if (!not_clear) {
            this.clear_except_background()
        }
        const lines = this.data.image_infos[this.current_index].lines
        const self = this
        lines.forEach(item => {
            this.group.add(new MoveableLine({ shape: { x: item }, scale: this.scale, height: this.current_image_info.height / 2, disable: this.active_step == 5 }, (t) => { self.focus_component = t }, () => { self.line_move_handler() }))
        })
    },
    line_move_handler() {
        const lines = []
        this.group.eachChild((item) => {
            if (item instanceof MoveableLine) {
                lines.push(item.opts.shape.x)
            }
        })
        this.data.image_infos[this.current_index].lines = lines.sort((a, b) => a - b)
    },
    addpaperClean() {
        this.add_level = 633
        this.init_draw_relative_event();
    },
    deletPaperDetele() {
        delete this.data.fucker.extra_info.paperScore
        this.show_all_other_flags()
    }
}