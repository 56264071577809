import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import VueContextMenu from 'vue-contextmenu'
import 'font-awesome/css/font-awesome.min.css'

Vue.use(VueContextMenu)
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.prototype.$http = axios;
Vue.prototype.console = console

new Vue({
  render: h => h(App),
}).$mount('#app')
//level 

// 任何一个item或者extra_info 都包含以下信息
//  level:  1 大题；2 二级小题； 3 三级小题； 100 答案; 51 常规学号 ； 52 涂卡学号  53 条形码学号 ; 54 二维码学号  41 分栏线 ；61：成绩框；62缺考标记； 71答题卡涂卡地方;  81 誊分框; 101涂卡区的ABCD等选项
//  dati_id
//  dati_num
//  xiaoti_id
//  xiaoti_num
//  third_xiaoti_id
//  third_xiaoti_num
//  area_index  如果是大题/小题，并且该大题小题有多个area，那么表示该area的索引，从0开始
//  answer_id
//  answer_index  从1开始的答案索引
//  file_index  该属于那种
//  
// 其中 dati_num,xiaoti_num,third_xiaoti_num没有具体的作用，仅仅是为了展示用
// level == 100 && third_xiaoti_num == undefined 表示一级小题的答案；level == 100 && third_xiaoti 表示二级小题的答案
// 
// area_index== undefined 表示是由右侧题目导航的item； area_index有具体的值表示是左边画图区点击的item


// 该item表示

