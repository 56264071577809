import axios from './axios.js'
import { Message, Loading } from 'element-ui';
export default {
    changeno_horizontal(v){
        const [x1,y1,x2,y2] = this.data.fucker.extra_info.student_no.position
        const self = this
        if(x2-x1 > y2 - y1 && v == 'false'){
            this.$confirm(
                "学号长度大于高度，应该是水平，是否确实改成垂直？",
                "提示",
                {
                  type: "warning",
                }
              ).then(() => {
                self.data.fucker.extra_info.student_no.horizontal=v
              }).catch(()=>{
                  self.no_horizontal = 'true'
                  self.data.fucker.extra_info.student_no.horizontal='true'
              });
        }else if(x2-x1 < y2 - y1 && v == 'true'){
            this.$confirm(
                "学号高度大于长度，应该是垂直，是否确实改成水平？",
                "提示",
                {
                  type: "warning",
                }
              ).then(() => {
                self.data.fucker.extra_info.student_no.horizontal=v
              }).catch(()=>{
                self.no_horizontal = 'false'
                self.data.fucker.extra_info.student_no.horizontal='false'
            });
        }else{
            self.data.fucker.extra_info.student_no.horizontal = v
        }
       
    },
    show_all_student_no(not_clear) {
        //展示所有存在的学号
        if(!not_clear){
            this.clear_except_background()
        }
        if(this.active_step != 2&&this.current_index !=0){
            return
        }
        let student_extra_info = this.data.fucker.extra_info
        //框选考号信息 除了学号姓名遮罩 其余在第二页不展示框框 只在第一页有
        if (this.current_index == 0) {
            if (student_extra_info.student_no && student_extra_info.student_no.position && student_extra_info.student_no.position.length) {
                //展示常规学号
                const position = student_extra_info.student_no.position
                this.group.add(this.generate_rectangle({ position, style: { stroke: "green" }, resizable: true, moveable:true },
                    { level: 51, horizontal: student_extra_info.student_no.horizontal }))
            }
            this.show_tuka_student_no();
            if (student_extra_info.student_no_b && student_extra_info.student_no_b.position && student_extra_info.student_no_b.position.length) {
                const position = this.data.fucker.extra_info.student_no_b.position
                this.group.add(this.generate_rectangle({ position, style: { stroke: "green" }, resizable: true,moveable:true },
                    { level: 53 }))
            }
            if (student_extra_info.student_no_c && student_extra_info.student_no_c.position && student_extra_info.student_no_c.position.length) {
                const position = this.data.fucker.extra_info.student_no_c.position
                this.group.add(this.generate_rectangle({ position, style: { stroke: "green" }, resizable: true ,moveable:true},
                    { level: 54 }))
            }
            
            if(student_extra_info.name && student_extra_info.name && student_extra_info.name.position && student_extra_info.name.position.length){
                const position = this.data.fucker.extra_info.name.position
                this.group.add(this.generate_rectangle({ position, style: { stroke: "green" }, resizable: true ,moveable:true},
                { level: 609 }))
            }
        }
        //学号姓名遮罩
        const { shadeFlag, maskList } = this.getCurrentIndex()
        if (shadeFlag) {
            const position = maskList[0].position
            this.group.add(this.generate_rectangle({
                position, style: { stroke: "green" },
                resizable: true, moveable: true
            },{ level: 55 }))
                
        }
        this.refresh_all_student_no_status()

    },
    show_tuka_student_no() {
        let student_extra_info = this.data.fucker.extra_info
        if (student_extra_info.student_no_a && student_extra_info.student_no_a.positions && student_extra_info.student_no_a.positions.length) {
            const self = this;
            this.data.fucker.extra_info.student_no_a.positions.forEach((item,index) => {
                self.group.add(
                    self.generate_rectangle({ position: item, style: { stroke: "red" }, moveable: true },
                        { level: 52 ,tuka_sno_index:index})
                );
            });
        }
    },
    refresh_all_student_no_status() {
        //刷新所有种类的学号的状态
        let student_extra_info = this.data.fucker.extra_info
        let abc = false
        if(student_extra_info.student_no && student_extra_info.student_no !=null && student_extra_info.student_no != undefined){
            abc = student_extra_info.student_no.horizontal==true?true:student_extra_info.student_no.horizontal=='true'
        }else{
            abc = false
        }
        const { shadeFlag } = this.getCurrentIndex()
        this.student_no_status = {
            student_no: student_extra_info.student_no && student_extra_info.student_no.position && student_extra_info.student_no.position.length,
            student_no_a: student_extra_info.student_no_a && student_extra_info.student_no_a.positions && student_extra_info.student_no_a.positions.length,
            student_no_b: student_extra_info.student_no_b && student_extra_info.student_no_b.position && student_extra_info.student_no_b.position.length,
            student_no_c: student_extra_info.student_no_c && student_extra_info.student_no_c.position && student_extra_info.student_no_c.position.length,
            student_no_shade:shadeFlag,
            student_no_horizontal: abc,
            name:student_extra_info.name && student_extra_info.name.position && student_extra_info.name.position.length,
        }
    },
    add_name:function(){
        this.add_level = 609
        this.init_draw_relative_event()
    },

    addstudent_no: function () {
        this.add_level = 51
        // this.clear_except_background()
        this.init_draw_relative_event();
    },
    tuka: function () {
        this.add_level = 52
        this.clear_except_background()
        this.init_draw_relative_event()
    },
    manual_tuka:function(){
        //手动添加涂卡学号
        this.add_level = 521
        this.init_draw_relative_event()
        // this.show_tuka_student_no()

    },
    tuka_student_no() {
        const self = this
        const { x, y, height, width } = this.add_tmp_element.opts.shape
        axios.post(this.host + "/get_tuka_sno", { shape: this.add_tmp_element.opts.shape, url: this.current_image_info.url }).then((a) => {
            self.data.fucker.extra_info.student_no_a = { "type": 2, positions: a.data.data, position_out: [x, y, x + width, y + height] }
            self.show_all_student_no()

        })
    },
    barcode() {
        this.add_level = 53
        // this.clear_except_background()
        this.init_draw_relative_event()
    },
    qrcode() {
        this.add_level = 54
        // this.clear_except_background()
        this.init_draw_relative_event()
    },
    shade() {
        //添加学号姓名遮罩
        this.add_level = 55
        this.init_draw_relative_event()
    },
    delete_no() {
        delete this.data.fucker.extra_info.student_no
        this.show_all_student_no()
    },
    delete_tuka() {
        delete this.data.fucker.extra_info.student_no_a
        this.show_all_student_no()
    },
    delete_barcode() {
        delete this.data.fucker.extra_info.student_no_b
        this.show_all_student_no()
    },
    //二维码学号删除
    delete_qrcode() {
        delete this.data.fucker.extra_info.student_no_c
        this.show_all_student_no()
    },
    //删除学号姓名遮罩
    delete_shade() {
        const {index} = this.getCurrentIndex()
        this.data.fucker.extra_info.student_no_shade.splice(index,1)
        this.show_all_student_no()
    },

    delete_name(){
        delete this.data.fucker.extra_info.name
        this.show_all_student_no()
    },

    //获取学号姓名遮罩 shadeFlag：当前页面有没有遮罩框 index当前遮罩层的框在数组的第几个 maskList：当前遮罩层数据
    getCurrentIndex() {
        const student_extra_info = this.data.fucker.extra_info
        let shadeFlag;
        let index = null;
        let maskList = []
        if (student_extra_info.student_no_shade && student_extra_info.student_no_shade.length){
            const shadeList = student_extra_info.student_no_shade
            maskList = shadeList.filter((item,i) => {
                if (item.index == this.current_index) {
                    index = i
                    return item
                }
            }
            )
            shadeFlag = maskList && maskList.length&&maskList[0].position&&maskList[0].position.length
        }
        return {shadeFlag,index,maskList}
    }

}

