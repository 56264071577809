<template>
  <div id="app">
    <el-container :style="{ height: '100%' }">
      <el-header height="80px">
        <div class="div-1">
          <div class="div-big-title-wrapper">
            <div
              :class="{
                'div-big-title': true,
                'div-big-title-focus': active_step == 1,
              }"
              v-on:click="to(1)"
            >
              1.标记分栏线
            </div>
            <div
              :class="{
                'div-big-title': true,
                'div-big-title-focus': active_step == 2,
              }"
              v-on:click="to(2)"
            >
              2.框选考号信息
            </div>
            <div
              :class="{
                'div-big-title': true,
                'div-big-title-focus': active_step == 4,
              }"
              v-on:click="to(4)"
            >
              3.其他标记
            </div>
            <div
              :class="{
                'div-big-title': true,
                'div-big-title-focus': active_step == 3,
              }"
              v-on:click="to(3)"
            >
              4.框选大小题及答案区
            </div>
            <div
              :class="{
                'div-big-title-1': true,
                'div-big-title-focus': active_step == 5,
              }"
              v-on:click="to(5)"
            >
              5.预览全部
            </div>
          </div>
          <div v-if="active_step == 3" class="div-2">
            <div class="div-3">
              <div
                :class="{
                  'div-3-1': true,
                  'div-big-title-focus':
                    step_in_step_3 == 1 ||
                    step_in_step_3 == 2 ||
                    step_in_step_3 == 3,
                }"
                v-on:click="todati"
                type="small"
              >
                框选大题
              </div>
              <span class="span-1">
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </span>
            </div>
            <div class="div-3">
              <div
                :class="{
                  'div-3-1': true,
                  'div-big-title-focus':
                    step_in_step_3 == 2 || step_in_step_3 == 3,
                }"
                v-on:click="toxiaoti"
                type="small"
              >
                框选小题
              </div>
              <span class="span-1">
                <i class="fa fa-arrow-right" aria-hidden="true"></i>
              </span>
            </div>
            <div class="div-3">
              <div
                :class="{
                  'div-3-1': true,
                  'div-big-title-focus': step_in_step_3 == 3,
                }"
                v-on:click="todaanqu"
                type="small"
              >
                框选答案区
              </div>
            </div>
          </div>
        </div>
        <div class="div-1_1">
          <el-tooltip
            class="item"
            placement="bottom"
            v-if="(active_step == 3 && step_in_step_3 == 3) || active_step == 5"
          >
            <div v-if="show_answer_text" slot="content">隐藏答案</div>
            <div v-if="!show_answer_text" slot="content">显示答案</div>
            <el-switch
              v-model="show_answer_text"
              active-color="#13ce66"
              inactive-color="#ff4949"
              @change="changeIfShowAnswerText"
            >
            </el-switch>
          </el-tooltip>
          <el-tooltip class="item" placement="bottom" v-if="active_step == 3">
            <div v-if="!move_image_after_click" slot="content">
              开启图像追踪功能
            </div>
            <div v-if="move_image_after_click" slot="content">
              关闭图像追踪功能
            </div>
            <span
              :class="{
                'span-btn': true,
                'span-btn-focus': move_image_after_click,
              }"
              v-on:click="move_image_after_click = !move_image_after_click"
            >
              <i class="el-icon-aim" />
            </span>
          </el-tooltip>
          <el-tooltip
            class="item"
            effect="dark"
            content="刷新"
            placement="bottom-start"
          >
            <span :class="{ 'span-btn': true }" v-on:click="reset">
              <i class="el-icon-refresh-left" />
            </span>
          </el-tooltip>
          <el-button
            type="danger"
            v-if="waitToRun != 3"
            v-on:click="rejectFormVisible = true"
            >驳回</el-button
          >
          <el-button type="primary" v-if="waitToRun != 3" v-on:click="tmp_save"
            >暂存</el-button
          >
          <el-button
            type="warning"
            v-if="waitToRun != 3"
            v-on:click="final_save"
            >完成</el-button
          >
        </div>
      </el-header>
      <el-container :style="{ height: 'calc(100% - 80px)' }">
        <el-aside :width="abcdefg">
          <el-container :style="{ width: '100%', height: '100%' }">
            <el-main class="canvas-wrapper_mother">
              <div
                class="canvas-wrapper"
                :style="{ width: '100%', height: '100%' }"
              >
                <div style="position: relative; width: 100%; height: 100%">
                  <div
                    class="canvas-wrapper_wrapper"
                    :style="{
                      top: wrapper_move.top + 'px',
                      left: wrapper_move.left + 'px',
                    }"
                  >
                    <div
                      class="canvas-div"
                      id="canvas1"
                      :style="{
                        width: current_image_info.width * scale + 'px ',
                        height: current_image_info.height * scale + 'px',
                        backgroundImage: 'url(' + current_image_info.url + ')',
                      }"
                    ></div>
                  </div>
                </div>
              </div>
            </el-main>
            <el-footer height="35px">
              <el-row>
                <el-col :span="4">
                  <el-button
                    type="primary"
                    size="mini"
                    v-on:click="previousPage"
                    :disabled="current_index == 0"
                    >上一页</el-button
                  >
                </el-col>
                <el-col :span="4">
                  <el-button
                    type="primary"
                    size="mini"
                    v-on:click="nextPage"
                    :disabled="current_index == max_index"
                    >下一页</el-button
                  >
                </el-col>
                <el-col :span="16">
                  <span :style="{ paddingRight: '50px' }"
                    >{{ current_index + 1 }}/{{ max_index + 1 }}</span
                  >
                  <span>{{ scale * 100 + "%" }}</span>
                </el-col>
              </el-row>
            </el-footer>
          </el-container>
        </el-aside>
        <el-main class="fucker-main" :style="{ height: '100%' }">
          <div :style="{ height: '100%', overflow: 'auto' }">
            <div :style="{ padding: '15px' }">
              <div v-if="active_step == 1">
                <div
                  v-for="(item, index) in lines"
                  v-bind:key="index + 'gitoqkcnpa'"
                >
                  <p>
                    <span>第{{ item.index + 1 }}页分栏：</span>
                    <span>
                      <el-radio-group
                        v-model="item.linevalue"
                        @change="changeLines"
                      >
                        <el-radio :label="item.index + '.0'">单栏</el-radio>
                        <el-radio :label="item.index + '.1'">双栏</el-radio>
                        <el-radio :label="item.index + '.2'">三栏</el-radio>
                      </el-radio-group>
                    </span>
                  </p>
                  <el-divider />
                </div>
              </div>
              <div v-if="active_step == 2">
                <el-divider></el-divider>
                <p class="p-title">
                  姓名<i class="header-icon el-icon-info"></i>
                </p>

                <el-button
                  v-on:click="add_name"
                  v-if="!student_no_status.name"
                  :disabled="current_index != 0"
                  >添加</el-button
                >
                <el-button
                  v-on:click="delete_name"
                  v-if="student_no_status.name"
                  :disabled="current_index != 0"
                  >刪除</el-button
                >
                <el-divider></el-divider>
                <p class="p-title">
                  常规学号<i class="header-icon el-icon-info"></i>
                </p>
                <div v-if="student_no_status.student_no">
                  <el-radio-group
                    v-model="no_horizontal"
                    @change="changeno_horizontal"
                    :disabled="current_index != 0"
                  >
                    <el-radio-button label="true">水平</el-radio-button>
                    <el-radio-button label="false">垂直</el-radio-button>
                  </el-radio-group>
                  <p>
                    <el-button
                      v-on:click="delete_no"
                      :disabled="current_index != 0"
                      >删除</el-button
                    >
                  </p>
                </div>
                <el-button
                  v-on:click="addstudent_no"
                  v-if="!student_no_status.student_no"
                  :disabled="current_index != 0"
                  >添加</el-button
                >

                <el-divider></el-divider>
                <p class="p-title">
                  涂卡学号<i class="header-icon el-icon-info"></i>
                </p>
                <el-button
                  v-on:click="tuka"
                  v-if="!student_no_status.student_no_a"
                  :disabled="current_index != 0"
                  >添加</el-button
                >
                <el-button
                  v-on:click="delete_tuka"
                  v-if="student_no_status.student_no_a"
                  :disabled="current_index != 0"
                  >删除</el-button
                >
                <el-button
                  v-on:click="manual_tuka"
                  :disabled="current_index != 0"
                  >手动添加</el-button
                >
                <el-divider></el-divider>
                <p class="p-title">
                  条形码学号<i class="header-icon el-icon-info"></i>
                </p>

                <el-button
                  v-on:click="barcode"
                  v-if="!student_no_status.student_no_b"
                  :disabled="current_index != 0"
                  >添加</el-button
                >
                <el-button
                  v-on:click="delete_barcode"
                  v-if="student_no_status.student_no_b"
                  :disabled="current_index != 0"
                  >刪除</el-button
                >
                <el-divider></el-divider>
                <p class="p-title">
                  二维码学号<i class="header-icon el-icon-info"></i>
                </p>
                <el-button
                  v-on:click="qrcode"
                  v-if="!student_no_status.student_no_c"
                  :disabled="current_index != 0"
                  >添加</el-button
                >
                <el-button
                  v-on:click="delete_qrcode"
                  v-if="student_no_status.student_no_c"
                  :disabled="current_index != 0"
                  >刪除</el-button
                >
                <el-divider></el-divider>
                <p class="p-title">
                  学号姓名遮罩<!-- <i class="header-icon el-icon-info"></i> -->
                </p>
                <el-button
                  v-on:click="shade"
                  v-if="!student_no_status.student_no_shade"
                  >添加</el-button
                >
                <el-button
                  v-on:click="delete_shade"
                  v-if="student_no_status.student_no_shade"
                  >刪除</el-button
                >
                <el-divider></el-divider>
              </div>
              <div v-if="active_step == 3">
                <div>
                  <el-collapse value="1">
                    <el-collapse-item title="答案库对比总览" name="1">
                      <div>
                        <p>题目/答案区丢失：</p>
                        <div class="div-difference-table">
                          <div class="div-difference-content">
                            <p
                              :class="{
                                'div-difference-content-p': step_in_step_3 == 3,
                              }"
                              :style="{ paddingLeft: '10px' }"
                              v-on:click="clickqueshao(item)"
                              v-for="(item, index) in question_difference.d2"
                              v-bind:key="index + 'dati'"
                            >
                              {{ item }}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <p>题目/答案区多余：</p>
                        <div class="div-difference-table">
                          <div class="div-difference-content">
                            <p
                              :class="{
                                'div-difference-content-p': step_in_step_3 == 3,
                              }"
                              :style="{ paddingLeft: '10px' }"
                              v-on:click="clickqueshao(item)"
                              v-for="(item, index) in question_difference.d1"
                              v-bind:key="index + 'dati'"
                            >
                              {{ item }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </el-collapse-item>
                  </el-collapse>
                </div>
                <div>
                  <div>
                    <div class="div-dati-single-title">
                      大题
                      <span class="abc" v-on:click="afterChangePage">
                        显示全部
                      </span>
                      <span
                        class="abc"
                        v-on:click="assemblynormalzone"
                        v-if="step_in_step_3 == 3"
                      >
                        获取答案区
                      </span>
                      <span
                        class="abc"
                        v-on:click="makeup_mx"
                        v-if="step_in_step_3 == 2"
                      >
                        获取小题
                      </span>
                    </div>
                    <div
                      v-for="item in sorted_list_question.datis"
                      v-bind:key="item.id"
                      class="div-dati-single-wrapper"
                    >
                      <div
                        :class="{
                          'div-dati-single': true,
                          'div-dati-single-focus':
                            item.dati_id == current_question.dati_id,
                        }"
                        v-on:click="clickDatiTitle(item)"
                      >
                        {{ item.dati_num }}
                      </div>
                      <div
                        class="my-badge"
                        v-on:click="deleteItem(item)"
                        v-if="step_in_step_3 == 1"
                      >
                        <i class="el-icon-close" />
                      </div>
                    </div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="添加大题"
                      placement="bottom-start"
                      v-if="step_in_step_3 == 1"
                    >
                      <div
                        class="div-dati-single el-button--primary"
                        v-on:click="onAddDati"
                      >
                        +
                      </div>
                    </el-tooltip>
                  </div>
                  <div v-if="step_in_step_3 != 1">
                    <div v-if="current_question.dati_id">
                      <el-divider></el-divider>
                      <div class="div-dati-single-title">小题</div>
                      <div
                        v-for="item in sorted_list_question.xiaotis"
                        v-bind:key="item.id"
                        class="div-dati-single-wrapper"
                      >
                        <div
                          :class="{
                            'div-dati-single': true,
                            'div-dati-single-focus':
                              item.xiaoti_id == current_question.xiaoti_id,
                          }"
                          v-on:click="clickXiaotiTitle(item)"
                        >
                          {{ item.xiaoti_num }}
                        </div>
                        <div
                          class="my-badge"
                          v-on:click="deleteItem(item)"
                          v-if="step_in_step_3 == 2"
                        >
                          <i class="el-icon-close" />
                        </div>
                      </div>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="添加三级小题"
                        placement="bottom-start"
                        v-if="current_question.dati_id && step_in_step_3 == 2"
                      >
                        <div
                          class="div-dati-single el-button--primary"
                          v-on:click="onAddXiaoti"
                        >
                          +
                        </div>
                      </el-tooltip>
                      <!-- <el-tooltip class="item" effect="dark" content="添加答题卡小题" placement="bottom-start" v-if="current_question.dati_id && step_in_step_3==2">
                        <div class="div-dati-single el-button--primary" v-on:click="onAddXiaotiDatika">添加涂卡小题</div>
                      </el-tooltip> -->
                    </div>
                    <div
                      v-if="
                        (step_in_step_3 == 2 &&
                          current_question.xiaoti_id &&
                          current_question.hasThirdLevel) ||
                          (step_in_step_3 == 3 &&
                            current_question.hasThirdLevel)
                      "
                    >
                      <el-divider></el-divider>
                      <div class="div-dati-single-title">二级小题1</div>
                      <div
                        v-for="item in sorted_list_question.third_xiaotis"
                        v-bind:key="item.id"
                        class="div-dati-single-wrapper"
                      >
                        <div
                          :class="{
                            'div-dati-single': true,
                            'div-dati-single-focus':
                              item.third_xiaoti_id ==
                              current_question.third_xiaoti_id,
                          }"
                          v-on:click="clickThirdXiaotiTitle(item)"
                        >
                          {{ item.third_xiaoti_num }}
                        </div>
                        <div
                          class="my-badge"
                          v-on:click="deleteItem(item)"
                          v-if="step_in_step_3 == 2"
                        >
                          <i class="el-icon-close" />
                        </div>
                      </div>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="添加小题"
                        placement="bottom-start"
                        v-if="current_question.dati_id && step_in_step_3 == 2"
                      >
                        <div
                          class="div-dati-single el-button--primary"
                          v-on:click="onAddThirdXiaoti"
                        >
                          +
                        </div>
                      </el-tooltip>
                    </div>
                  </div>
                  <div
                    v-if="
                      step_in_step_3 == 3 &&
                        (sorted_list_question.third_xiaotis.length == 0 ||
                          current_question.third_xiaoti_id)
                    "
                  >
                    <div
                      v-if="
                        current_question.xiaoti_id ||
                          current_question.third_xiaoti_id
                      "
                    >
                      <el-divider></el-divider>
                      <div class="div-dati-single-title">答案区</div>
                      <div
                        v-for="item in sorted_list_question.answer_positions"
                        v-bind:key="item.id"
                        class="div-dati-single-wrapper"
                      >
                        <div
                          :class="{
                            'div-dati-single': true,
                            'div-dati-single-focus':
                              item.answer_index ==
                              current_question.answer_index,
                          }"
                          v-on:click="clickAnswer(item)"
                        >
                          {{ item.answer_index }}
                        </div>
                        <div
                          class="my-badge"
                          v-on:click="deleteItem(item)"
                          v-if="step_in_step_3 == 3"
                        >
                          <i class="el-icon-close" />
                        </div>
                      </div>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="添加答案区"
                        placement="bottom-start"
                        v-if="current_question.xiaoti_id && step_in_step_3 == 3"
                      >
                        <div
                          class="div-dati-single el-button--primary"
                          v-on:click="onAddAnswer"
                        >
                          +
                        </div>
                      </el-tooltip>
                    </div>
                  </div>
                  <div
                    v-if="
                      step_in_step_3 == 3 &&
                        current_question.is_datika == '1' &&
                        current_question.level == 100
                    "
                  >
                    <el-divider></el-divider>
                    <div class="div-dati-single-title">涂卡区</div>
                    <div
                      v-for="item in sorted_list_question.tuka_positions"
                      v-bind:key="item.id"
                      class="div-dati-single-wrapper"
                    >
                      <div
                        :class="{
                          'div-dati-single': true,
                          'div-dati-single-focus':
                            item.tuka_index == current_question.tuka_index,
                        }"
                        v-on:click="clicktuka(item)"
                      >
                        {{ item.tuka_index }}
                      </div>
                      <div
                        class="my-badge"
                        v-on:click="deleteItem(item)"
                        v-if="step_in_step_3 == 3"
                      >
                        <i class="el-icon-close" />
                      </div>
                    </div>
                    <el-tooltip
                      class="item"
                      effect="dark"
                      content="添加答案区"
                      placement="bottom-start"
                    >
                      <div
                        class="div-dati-single el-button--primary"
                        v-on:click="onAddTuka"
                      >
                        +
                      </div>
                    </el-tooltip>
                  </div>
                </div>
              </div>
              <div v-if="active_step == 4">
                <el-divider></el-divider>
                <p class="p-title">
                  成绩框<i class="header-icon el-icon-info"></i>
                </p>
                <el-divider></el-divider>
                <p class="p-title">
                  缺考标记<i class="header-icon el-icon-info"></i>
                </p>
                <el-button
                  v-on:click="addquekao"
                  v-if="!other_flag_status.quekao"
                  >添加</el-button
                >
                <el-button
                  v-on:click="deletequekao"
                  v-if="other_flag_status.quekao"
                  >删除</el-button
                >

                <div>
                  <el-divider></el-divider>
                  <p class="p-title">
                    卷面分<i class="header-icon el-icon-info"></i>
                  </p>
                  <el-button
                    @click="addpaperClean"
                    v-if="!other_flag_status.paperScore"
                    >添加</el-button
                  >
                  <el-button
                    @click="deletPaperDetele"
                    v-if="other_flag_status.paperScore"
                    >删除</el-button
                  >
                </div>
              </div>
              <div v-if="active_step == 5">
                <div>
                  <div>
                    <p class="ppp-1">
                      <span class="ppp-1-span-1">分栏：</span>
                      <span class="ppp-1-span-2">
                        {{ yulanstatus_line }}
                      </span>
                    </p>
                    <p class="ppp-1" v-if="student_no_status.student_no">
                      <span class="ppp-1-span-1">手写学号：</span>
                      <span class="ppp-1-span-2">
                        {{
                          student_no_status.student_no
                            ? student_no_status.student_no_horizontal
                              ? "水平"
                              : "垂直"
                            : "无"
                        }}
                      </span>
                    </p>
                    <p class="ppp-1" v-if="student_no_status.student_no_a">
                      <span class="ppp-1-span-1">涂卡学号：</span
                      ><span class="ppp-1-span-2">有</span>
                    </p>
                    <p class="ppp-1" v-if="student_no_status.student_no_b">
                      <span class="ppp-1-span-1">条形码学号：</span
                      ><span class="ppp-1-span-2">有</span>
                    </p>
                    <p class="ppp-1" v-if="student_no_status.student_no_c">
                      <span class="ppp-1-span-1">二维码学号：</span
                      ><span class="ppp-1-span-2">有</span>
                    </p>
                    <p class="ppp-1" v-if="other_flag_status.quekao">
                      <span class="ppp-1-span-1">缺考标记：</span>
                      <span class="ppp-1-span-2">有</span>
                    </p>
                    <p class="ppp-1" v-if="other_flag_status.chengjikuang">
                      <span class="ppp-1-span-1">总成绩框：</span>
                      <span class="ppp-1-span-2">有</span>
                    </p>
                  </div>
                  <div>
                    <div class="div-dati-single-title">
                      大题
                      <span
                        ><i
                          class="el-icon-caret-right"
                          v-on:click="afterChangePage"
                        />
                      </span>
                    </div>
                    <div
                      v-for="item in sorted_list_question.datis"
                      v-bind:key="item.id"
                      class="div-dati-single-wrapper"
                    >
                      <div
                        :class="{
                          'div-dati-single': true,
                          'div-dati-single-focus':
                            item.dati_id == current_question.dati_id,
                        }"
                        v-on:click="clickDatiTitle(item)"
                      >
                        {{ item.dati_num }}
                      </div>
                    </div>
                    <el-divider></el-divider>
                  </div>
                  <div>
                    <div class="div-dati-single-title">小题</div>
                    <div
                      v-for="item in sorted_list_question.xiaotis"
                      v-bind:key="item.id"
                      class="div-dati-single-wrapper"
                    >
                      <div
                        :class="{
                          'div-dati-single': true,
                          'div-dati-single-focus':
                            item.xiaoti_id == current_question.xiaoti_id,
                        }"
                        v-on:click="clickXiaotiTitle(item)"
                      >
                        {{ item.xiaoti_num }}
                      </div>
                      <div
                        class="my-badge"
                        v-on:click="deleteItem(item)"
                        v-if="step_in_step_3 == 2"
                      >
                        <i class="el-icon-close" />
                      </div>
                    </div>
                    <el-divider></el-divider>
                    <div class="div-dati-single-title">二级小题</div>
                    <div
                      v-for="item in sorted_list_question.third_xiaotis"
                      v-bind:key="item.id"
                      class="div-dati-single-wrapper"
                    >
                      <div
                        :class="{
                          'div-dati-single': true,
                          'div-dati-single-focus':
                            item.third_xiaoti_id ==
                            current_question.third_xiaoti_id,
                        }"
                        v-on:click="clickThirdXiaotiTitle(item)"
                      >
                        {{ item.third_xiaoti_num }}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="div-dati-single-title">答案区</div>
                    <div
                      v-for="item in sorted_list_question.answer_positions"
                      v-bind:key="item.id"
                      class="div-dati-single-wrapper"
                    >
                      <div
                        :class="{
                          'div-dati-single': true,
                          'div-dati-single-focus':
                            item.answer_index == current_question.answer_index,
                        }"
                        v-on:click="clickAnswer(item)"
                      >
                        {{ item.answer_index }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div :style="{ padding: '15px' }">
              <div v-if="step_in_step_3 != 3 && active_step == 3">
                <p>属性</p>
                <p v-if="step_in_step_3 == 1 && current_question.level">
                  <span>
                    <el-input
                      v-model="current_question.dati_num"
                      @change="changeDatiNum"
                    >
                      <template slot="prepend">大题:</template>
                    </el-input>
                  </span>
                </p>
                <p v-if="current_question.level && current_question.level != 1">
                  <el-form label-width="80px">
                    <el-form-item label="作答方式">
                      <el-radio-group
                        v-model="current_question.is_datika"
                        @change="changeDatikaStatus"
                      >
                        <el-radio-button label="1" border>涂卡</el-radio-button>
                        <el-radio-button label="0" border
                          >非涂卡</el-radio-button
                        >
                      </el-radio-group>
                    </el-form-item>
                    <el-form-item label="小题">
                      <el-input
                        v-model="current_question.xiaoti_num"
                        @change="changeXiaotiNum"
                      >
                      </el-input>
                    </el-form-item>
                    <div
                      v-if="
                        examBaseInfo.markPaperType &&
                          current_question.autoFlag == 0
                      "
                    >
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="添加誊分框"
                        placement="top-start"
                        v-if="
                          !current_question.score_position &&
                            !current_question.autoFlag
                        "
                      >
                        <div
                          class="div-dati-single el-button--primary"
                          v-on:click="addtengfenkuang"
                          :style="{ width: '100px' }"
                        >
                          添加誊分框
                        </div>
                      </el-tooltip>
                      <el-tooltip
                        class="item"
                        effect="dark"
                        content="删除誊分框"
                        placement="top-start"
                        v-if="
                          current_question.score_position &&
                            !current_question.autoFlag
                        "
                      >
                        <div
                          class="div-dati-single el-button--primary"
                          v-on:click="deletetengfenkuang"
                          :style="{ width: '100px' }"
                        >
                          删除誊分框
                        </div>
                      </el-tooltip>
                    </div>
                  </el-form>
                </p>
              </div>
            </div>
          </div>
        </el-main>
      </el-container>
    </el-container>
    <el-dialog
      title="添加"
      :visible.sync="addFormVisible"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="closeonAddXiaotiDatika"
    >
      <el-form label-width="120px">
        <el-form-item label="作答方式" v-if="add_level == 2">
          <el-radio-group v-model="addxiaotitype">
            <el-radio label="n">手写作答</el-radio>
            <el-radio label="d">涂卡</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <el-form
        :model="addForm"
        label-width="120px"
        v-if="addxiaotitype == 'n' || add_level == 1 || add_level == 3"
        :rules="addFormRules"
        ref="addForm"
      >
        <el-form-item label="大题题号" prop="dati_num">
          <el-input
            v-model="addForm.dati_num"
            :disabled="add_level != 1"
          ></el-input>
        </el-form-item>
        <el-form-item label="小题题号" v-if="add_level != 1" prop="xiaoti_num">
          <el-input
            v-model="addForm.xiaoti_num"
            :disabled="add_level != 2"
          ></el-input>
        </el-form-item>
        <el-form-item
          label="二级小题题号"
          v-if="
            add_level == 3 ||
              (add_level == 100 && current_question.third_xiaoti_id)
          "
          prop="third_xiaoti_num"
        >
          <el-input
            v-model="addForm.third_xiaoti_num"
            :disabled="add_level != 3"
          ></el-input>
        </el-form-item>
        <el-form-item label="答案序号" v-if="add_level == 100">
          <el-input v-model="addForm.answer_index"></el-input>
        </el-form-item>
        <el-form-item label="答案区类型" v-if="add_level == 100">
          <el-select v-model="addForm.zone_type">
            <el-option value="1" label="常规类型"></el-option>
            <el-option value="6" label="田字格"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="beforeClose">取 消</el-button>
          <el-button type="primary" @click="ensureAddform">确 定</el-button>
        </el-form-item>
      </el-form>
      <el-form :model="form" label-width="120px" v-if="addxiaotitype == 'd'">
        <el-form-item label="是否连续">
          <el-radio-group v-model="form.type1">
            <el-radio label="1">是</el-radio>
            <el-radio label="0">否</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="小题数量" v-if="form.type1 == 0">
          <el-input-number
            v-model="form.nums"
            :min="1"
            :max="16"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="小题题号" v-if="form.type1 == 0">
          <el-input
            :style="{ width: '40px', display: 'inline-block' }"
            v-model="form['nnn' + index]"
            v-for="(item, index) in form.nums"
            v-bind:key="index"
          ></el-input>
        </el-form-item>
        <el-form-item label="小题题号" v-if="form.type1 == 1">
          <el-input
            v-model="form.begin"
            :style="{ width: '50px', display: 'inline-block' }"
          ></el-input>
          -
          <el-input
            v-model="form.end"
            :style="{ width: '50px', display: 'inline-block' }"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button @click="beforeClose">取消</el-button>
          <el-button type="primary" @click="onSubmit">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <el-dialog
      title="驳回"
      :visible.sync="rejectFormVisible"
      width="500px"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form :model="rejectform" label-width="120px">
        <el-form-item label="理由">
          <el-radio-group v-model="rejectform.reason">
            <el-radio
              label="1"
              :style="{ display: 'block', margin: '10px' }"
              v-if="examBaseInfo.branchExam != 1"
              >扫描的试卷和考试试卷不匹配</el-radio
            >
            <el-radio label="2" :style="{ display: 'block', margin: '10px' }"
              >试卷扫描顺序错误</el-radio
            >
            <el-radio label="3" :style="{ display: 'block', margin: '10px' }"
              >试卷扫描角度倾斜</el-radio
            >
            <el-radio label="4" :style="{ display: 'block', margin: '10px' }"
              >空白试卷错误，需重新扫描空白试卷</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button @click="rejectFormVisible = false">取消</el-button>
          <el-button type="primary" @click="onreject">确定</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <div
      v-if="active_step != 0"
      id="div-snapshot-pointer-b"
      class="div-snapshot"
      :style="{
        width: current_image_info.width / 30 + 'px',
        height: current_image_info.height / 30 + 'px',
        backgroundImage: 'url(' + current_image_info.url + ')',
        backgroundSize:
          current_image_info.width / 30 +
          'px ' +
          current_image_info.height / 30 +
          'px',
      }"
    >
      <div
        id="div-snapshot-pointer-a"
        class="div-snapshot-pointer"
        :style="{
          width: snap_shot_point.width + 'px',
          height: snap_shot_point.height + 'px',
          top: snap_shot_point.top + 'px',
          left: snap_shot_point.left + 'px',
        }"
      ></div>
    </div>
  </div>
</template>

<script>
// extra_info: {level:, dati_num,xiaoti_num,third_xiaoti_num,answer_index,area_index:} answer_index 和area_index从0开始计数，area_index表示是某大题、小题、答案区的第几个area
import zrender from "zrender";
import config from "./service/config";
// import QuestionEditor from "./components/QuestionEditor";
import uuidv1 from "uuid/v1";
import { ResizableRectangle, MoveableLine } from "./components/rectangle.js";
import {
  convert,
  find_dati_id,
  log,
  reverse_convert,
  convert_single_page,
  reverse_convert_single_page,
} from "./components/static_data.js";
import other_methods from "./service/other_service.js";
import struct_methods from "./service/struct_service.js";
import student_no_methods from "./service/student_no_service.js";
import page_methods from "./service/page_service.js";
import axios from "./service/axios.js";
import { Message } from "element-ui";
import Cookies from "js-cookie";
export default {
  name: "app",
  data() {
    const self = this;
    var validatedatinum = (rule, value, callback) => {
      if (self.answer_list[value] || self.answer_list[parseInt(value)]) {
        callback();
      } else {
        callback(new Error("不能添加不存在的大题"));
      }
    };
    var validatexiaotinum = (rule, value, callback) => {
      if (
        self.answer_list[self.current_question.dati_num].xiaotis[
          parseInt(value)
        ]
      ) {
        callback();
      } else {
        callback(new Error("不能添加不存在的小题"));
      }
    };
    var validatethirdxiaotinum = (rule, value, callback) => {
      if (
        self.answer_list[self.current_question.dati_num].xiaotis[
          self.current_question.xiaoti_num
        ].xiaotis[value]
      ) {
        callback();
      } else {
        callback(new Error("不能添加不存在的二级小题"));
      }
    };

    return {
      waitToRun: 0,
      abcdefg: "80%",
      form: {
        nums: 5,
        begin: 1,
        end: 5,
        type1: "1",
        type2: "1",
        position: undefined,
      },
      rejectFormVisible: false,
      rejectform: {
        reason: "1",
      },
      no_horizontal: undefined,
      active_step: 1,
      step_in_step_3: 1, //在第三步中其实还分三步，分别是大题，小题，答案区
      scale: 0.4,
      group: undefined, // 用于底图的构建，底图上存放所有的已经画出来的图形
      zr: undefined,
      mouse_pointer: { x: 0, y: 0 },
      list_question: {
        //用于左侧展大题、小题、答案区，用于点击
        datis: [],
        xiaotis: [],
        third_xiaotis: [],
        answer_positions: [],
      },
      current_question: {
        // 从右侧导航区 当前点击的题目/答案区
        level: undefined,
        dati_id: undefined,
        dati_num: undefined, // 大题的相关信息
        xiaoti_id: undefined,
        xiaoti_num: undefined, //小题的相关信息
        third_xiaoti_id: undefined,
        third_xiaoti_num: undefined, // 三级小题的相关信息
        answer_index: undefined,
        is_datika: undefined, // 在level是2和3时有用，表示该到小题是否时答题卡的涂卡类型
        autoFlag: undefined, //在level时2和3时有用，表示该小题是否
      },
      focus_component: null, //从左侧画图区被点击的组件,
      answer_zones: [
        [
          [100, 100, 200, 200, 1],
          [400, 400, 500, 500, 2],
        ],
      ],
      data: undefined,
      current_image_info: {
        url: "",
        lines: [0],
        height: 1000,
        width: 1000,
      },
      background_image: null, // zrender对象，表示背景图片
      fixed_area: { width: 0, height: 0 },
      wrapper_move: { top: 0, left: 0 },
      current_index: 0, //当前页的索引
      max_index: 1, //最大的页面索引

      add_level: 0, //添加题目的级别
      addFormVisible: false,
      addForm: {
        dati_num: undefined,
        xiaoti_num: undefined,
        answer_index: undefined,
        third_xiaoti_num: undefined,
        zone_type: "1",
      },
      add_tmp_element: undefined,

      host: "http://localhost:9920",
      student_no_status: {}, //各种学号的状态，能够表示是否显示
      other_flag_status: {}, //在“其他标记”里面的成绩框和缺考标记的显示
      questions: {}, // 答案库中获取的题目、答案，用于比较画图是否有错误
      question_difference: { d1: [], d2: [] }, // 画出来的题目与实际答案库的不同点
      lines: [], // 分栏线,
      page_moveable: false,
      snap_shot_point: {
        top: "0px",
        left: "0px",
        width: "0px",
        height: "0px",
      },
      isFullScreen: false,
      addxiaotitype: "n", //添加小题的时候要增加的类型：有两种类型 'n'表示常规小题；'d'表示答题卡的涂卡小题
      yulanstatus_line: undefined,
      examBaseInfo: {
        markPaperType: undefined,
        branchExam: 0,
      },
      answer_list: undefined,
      addFormRules: {
        dati_num: [{ validator: validatedatinum, trigger: "change" }],
        xiaoti_num: [{ validator: validatexiaotinum, trigger: "change" }],
        third_xiaoti_num: [
          { validator: validatethirdxiaotinum, trigger: "change" },
        ],
      },
      move_image_after_click: false,
      actual_struct: undefined,
      ahost: "",
      show_answer_text: false,
    };
  },
  mounted() {
    // 添加圆到group里

    const auth = Cookies.get("platform@token");
    // if ((auth == undefined || auth == '' ) && config.rd !=''){
    // window.location.href = config.rd
    // }

    this.init_data();
    this.init_div_snapshot_pointer();
  },
  computed: {
    sorted_list_question() {
      const lq = this.list_question;
      return {
        datis: lq.datis ? lq.datis.sort((a, b) => a.dati_num - b.dati_num) : [],
        xiaotis: lq.xiaotis
          ? lq.xiaotis.sort((a, b) => a.xiaoti_num - b.xiaoti_num)
          : [],
        answer_positions: lq.answer_positions
          ? lq.answer_positions.sort((a, b) => a.answer_index - b.answer_index)
          : [],
        third_xiaotis: lq.third_xiaotis
          ? lq.third_xiaotis.sort((a, b) => a.xiaoti_num - b.xiaoti_num)
          : [],
        tuka_positions: lq.tuka_positions ? lq.tuka_positions : [],
      };
    },
    review() {
      let handwriting_student_no = "";
      if (
        this.data.fucker.extra_info.student_no &&
        this.data.fucker.extra_info.student_no.position
      ) {
        handwriting_student_no =
          this.data.fucker.extra_info.student_no.horizontal == "true" ||
          this.data.fucker.extra_info.student_no.horizontal == true
            ? "水平"
            : "垂直";
      } else {
        handwriting_student_no = "无";
      }
      return {
        handwriting_student_no,
      };
    },
  },
  // level  1 大题；2 二级小题； 3 三级小题； 100 答案; 51 常规学号 ； 52 涂卡学号  53 条形码学号 ;41 分栏线
  methods: {
    ...other_methods,
    ...struct_methods,
    ...student_no_methods,
    ...page_methods,
    init_data() {
      const self = this;
      // 32a93a8e-6361-43ec-8c3c-da82d799a5a1
      // 1fcdb805-85fe-4f66-9655-62d32846eea6
      // e58a760b-98d9-4ec7-8aae-9fe682ecb2d3 三级小题
      const id = this.getUrlKey("id");
      this.host = config.host;
      this.ahost = config.ahost;
      axios.get(this.host + "/get?id=" + id).then((d) => {
        const all_data = d.data;
        self.waitToRun = all_data.waitToRun;
        if (
          !all_data.fucker.extra_info.chengjikuang ||
          !all_data.fucker.extra_info.chengjikuang.position
        ) {
          all_data.fucker.extra_info.chengjikuang = {
            position: [100, 100, 300, 300],
          };
        }
        self.data = all_data;
        self.questions = all_data.questions;
        self.max_index = all_data.image_infos.length - 1;
        const tmp_lines = [];
        for (let i = 0; i < all_data.image_infos.length; i++) {
          tmp_lines.push({
            index: i,
            linevalue: i + "." + all_data.image_infos[i].lines.length + "",
          });
        }
        self.answer_list = all_data.answer_list;
        self.examBaseInfo = all_data.exam_info;
        self.lines = tmp_lines;
        self.no_horizontal =
          all_data.fucker.extra_info.student_no &&
          all_data.fucker.extra_info.student_no.horizontal;
        self.actual_struct = convert_single_page(all_data.actual_struct);
        self.init_window_scale_and_move_and_move_event();
        self.init_fixed_area(self.current_index);
        self.init_page();
        self.afterChangePage();
        // self.to(3)
        self.refreshSnapshot();
      });
    },

    initBackground() {
      //根据当前的current_image_info决定zr的大小，背景图片等等
      // 因为改变任何一张图片，都要改变背后的zrender的小题和背景图片，所以必须走这一步
      this.current_image_info = this.data.image_infos[this.current_index];
      this.zr.resize({
        width: this.current_image_info.width * this.scale,
        height: this.current_image_info.height * this.scale,
      });
      this.focus_component = undefined;
    },
    onreject() {
      this.rejectFormVisible = false;
      const auth = Cookies.get("platform@token");
      axios
        .post(
          this.ahost + "/managePlatform/examPosition/reject",
          {
            checkResult: 0,
            examId: this.data.exam_id,
            rejectReason: this.rejectform.reason,
          },
          {
            headers: {
              // Authorization: auth,
            },
          }
        )
        .then((res) => {
          if (res.data.code == "E-111405") {
            Message.error(res.data.message);
          } else if (res.data.code == "E-040019") {
            Message.error(res.data.message);
          } else {
            Message.success("保存成功");
          }
        });
    },

    deleteItem: function({
      dati_id,
      xiaoti_id,
      area_index,
      level,
      answer_index,
      third_xiaoti_id,
      dati_num,
      tuka_index,
    }) {
      // 刪除元素（大题，小题，答案区）
      if (level == 1 && this.step_in_step_3 == 1) {
        //删除大题
        if (
          area_index == undefined ||
          this.actual_struct[dati_id].areas.length == 1
        ) {
          delete this.actual_struct[dati_id];
        } else {
          this.actual_struct[dati_id].areas.splice(area_index, 1);
        }
        this.compare_dati();
        this.init_question_exhibition_2();
        this.render_all_datis();
      } else if (level == 2 && this.step_in_step_3 == 2) {
        //删除小题
        if (
          area_index == undefined ||
          this.actual_struct[dati_id].xiaotis[xiaoti_id].areas.length == 1
        ) {
          delete this.actual_struct[dati_id].xiaotis[xiaoti_id];
        } else {
          this.actual_struct[dati_id].xiaotis[xiaoti_id].areas.splice(
            area_index,
            1
          );
        }
        this.compare_xiaoti();
        this.init_question_exhibition_2();
        this.render_all_datis();
      } else if (level == 3) {
        delete this.actual_struct[dati_id].xiaotis[xiaoti_id].xiaotis[
          third_xiaoti_id
        ];
        this.compare_xiaoti();
        this.init_question_exhibition_2();
        this.render_all_datis();
      } else if (level == 100 && this.step_in_step_3 == 3) {
        if (!third_xiaoti_id) {
          this.actual_struct[dati_id].xiaotis[
            xiaoti_id
          ].answer_positions.splice(answer_index - 1, 1);
        } else {
          this.actual_struct[dati_id].xiaotis[xiaoti_id].xiaotis[
            third_xiaoti_id
          ].answer_positions.splice(answer_index - 1, 1);
        }
        this.compare_answers();
        this.init_question_exhibition_2();
        this.render_all_datis();
      } else if (level == 52) {
        // this.group.remove(this.focus_component)

        this.data.fucker.extra_info.student_no_a.positions.splice(
          this.focus_component.extra_info.tuka_sno_index,
          1
        );
        this.show_all_student_no();
      } else if (level == 41) {
        this.group.remove(this.focus_component);
        this.line_move_handler();
      } else if (level == 53) {
        this.group.remove(this.focus_component);
        this.data.fucker.extra_info.student_no_b = {};
      } else if (level == 54) {
        this.group.remove(this.focus_component);
        this.data.fucker.extra_info.student_no_c = {};
      } else if (level == 101) {
        this.actual_struct[dati_id].xiaotis[xiaoti_id].answer_positions[
          parseInt(answer_index) - 1
        ].tuka_positions.splice(tuka_index - 1, 1);
        this.init_question_exhibition_2();
        this.render_all_datis();
      }
    },

    clear_except_background: function() {
      this.group.dirty();
      this.group.removeAll();
    },

    init_move_relative_event: function() {
      // 正常情况下鼠标移动事件处理
      const self = this;
      this.zr.on("mousemove", (e) => {
        self.mouse_pointer.x = parseInt(e.offsetX / self.scale);
        self.mouse_pointer.y = parseInt(e.offsetY / self.scale);
      });
    },

    clear_zr_mouse_event: function() {
      this.zr.off("mousemove");
      this.zr.off("mousedown");
      this.zr.off("mouseup");
    },

    init_draw_relative_event: function() {
      //鼠标拖拽画框事件处理
      const self = this;
      this.zr.on("mousedown", (e) => {
        let [x1, y1] = [e.offsetX / self.scale, e.offsetY / self.scale];
        let tmp_element = undefined;
        self.zr.on("mousemove", (e) => {
          self.group.remove(tmp_element);
          let [x2, y2] = [e.offsetX / self.scale, e.offsetY / self.scale];
          tmp_element = self.generate_rectangle(
            {
              position: [x1, y1, x2, y2],
              style: {
                stroke: "green",
              },
              id: uuidv1(),
            },
            { level: self.add_level }
          );
          self.group.add(tmp_element);
        });
        self.zr.on("mouseup", () => {
          self.on_zr_mouse_up();
          self.after_draw(tmp_element);
        });
      });
    },
    after_draw: function(tmp) {
      this.add_tmp_element = tmp;
      if (this.add_level == 1) {
        this.addForm = {
          dati_num: this.guess_lacked_dati_num(),
          xiaoti_num: undefined,
          answer_index: undefined,
        };
        this.addFormVisible = true;
      } else if (this.add_level == 2) {
        this.addForm = {
          dati_num: this.current_question.dati_num,
          xiaoti_num: this.guess_lacked_xiaoti_num(),
        };
        this.addFormVisible = true;
      } else if (this.add_level == 3) {
        this.addForm = {
          dati_num: this.current_question.dati_num,
          xiaoti_num: this.current_question.xiaoti_num,
          third_xiaoti_num: undefined,
        };
        this.addFormVisible = true;
      } else if (this.add_level == 100) {
        this.addForm = {
          dati_num: this.current_question.dati_num,
          xiaoti_num: this.current_question.xiaoti_num,
          answer_index: 1,
          third_xiaoti_num: this.current_question.third_xiaoti_num,
          zone_type: "1",
        };
        this.addFormVisible = true;
      } else if (this.add_level == 51) {
        const { x, y, width, height } = this.add_tmp_element.opts.shape;
        this.data.fucker.extra_info.student_no = {
          position: [x, y, x + width, y + height],
          horizontal: true,
        };
        this.no_horizontal = true;
        this.show_all_student_no();
      } else if (this.add_level == 52) {
        //涂卡学号整体添加
        const { x, y, width, height } = this.add_tmp_element.opts.shape;
        const self = this;
        this.group.remove(this.add_tmp_element);
        this.tuka_student_no();
      } else if (this.add_level == 521) {
        //涂卡学号单个添加
        const { x, y, width, height } = this.add_tmp_element.opts.shape;
        if (
          this.data.fucker.extra_info.student_no_a &&
          this.data.fucker.extra_info.student_no_a.positions
        ) {
          this.data.fucker.extra_info.student_no_a.positions.push([
            x,
            y,
            x + width,
            y + height,
          ]);
        } else {
          this.data.fucker.extra_info.student_no_a = {
            positions: [[x, y, x + width, y + height]],
          };
        }
        this.show_all_student_no();
      } else if (this.add_level == 53) {
        // 条形码学号
        const { x, y, width, height } = this.add_tmp_element.opts.shape;
        this.data.fucker.extra_info.student_no_b = {
          position: [x, y, x + width, y + height],
        };
        this.show_all_student_no();
      } else if (this.add_level == 609) {
        //姓名
        const { x, y, width, height } = this.add_tmp_element.opts.shape;
        this.data.fucker.extra_info.name = {
          position: [x, y, x + width, y + height],
        };
        this.show_all_student_no();
      } else if (this.add_level == 54) {
        //二维码学号
        const { x, y, width, height } = this.add_tmp_element.opts.shape;
        this.data.fucker.extra_info.student_no_c = {
          position: [x, y, x + width, y + height],
        };
        this.show_all_student_no();
      } else if (this.add_level == 55) {
        //学号姓名遮罩
        const { x, y, width, height } = this.add_tmp_element.opts.shape;
        if (!this.data.fucker.extra_info.student_no_shade) {
          this.data.fucker.extra_info.student_no_shade = [];
        }
        //每一页第一次添加的时候
        this.data.fucker.extra_info.student_no_shade.push({
          index: this.current_index,
          position: [x, y, x + width, y + height],
        });
        this.show_all_student_no();
      } else if (this.add_level == 61) {
        const { x, y, width, height } = this.add_tmp_element.opts.shape;
        this.data.fucker.extra_info.chengjikuang = {
          position: [x, y, x + width, y + height],
        };
        this.show_all_other_flags();
      } else if (this.add_level == 62) {
        const { x, y, width, height } = this.add_tmp_element.opts.shape;
        this.data.fucker.extra_info.quekao = {
          position: [x, y, x + width, y + height],
        };
        this.show_all_other_flags();
      } else if (this.add_level == 633) {
        const { x, y, width, height } = this.add_tmp_element.opts.shape;
        this.data.fucker.extra_info.paperScore = {
          position: [x, y, x + width, y + height],
          file_index: this.current_index,
        };
        this.show_all_other_flags();
      } else if (this.add_level == 71) {
        // 答题卡涂卡地方
        this.addFormVisibledatika = true;
        const { x, y, width, height } = this.add_tmp_element.opts.shape;
        this.form.position = [x, y, x + width, y + height];
      } else if (this.add_level == 81) {
        // 誊分框
        const {
          dati_id,
          xiaoti_id,
          dati_num,
          xiaoti_num,
        } = this.current_question;
        const { x, y, width, height } = this.add_tmp_element.opts.shape;
        this.actual_struct[dati_id].xiaotis[xiaoti_id].score_position = {
          position: [x, y, x + width, y + height],
          file_index: this.current_index,
          bar_index: 0,
        };
        this.current_question = {
          ...this.current_question,
          score_position: true,
        };
      } else if (this.add_level == 101) {
        // 涂卡区
        const {
          dati_id,
          xiaoti_id,
          dati_num,
          xiaoti_num,
          answer_index,
        } = this.current_question;
        const { x, y, width, height } = this.add_tmp_element.opts.shape;
        this.actual_struct[dati_id].xiaotis[xiaoti_id].answer_positions[
          parseInt(answer_index) - 1
        ].tuka_positions.push([x, y, x + width, y + height]);
        this.init_question_exhibition_2();
        this.fucker_render_one_xiaoti({
          ...this.actual_struct[dati_id].xiaotis[xiaoti_id],
          dati_num,
          dati_id,
        });
      }
    },
    beforeClose: function() {
      this.group.remove(this.add_tmp_element);
      this.addFormVisible = false;
    },

    on_zr_mouse_up: function() {
      this.zr.off("mousemove");
      this.zr.off("mousedown");
      this.zr.off("mouseup");
      this.init_move_relative_event();
    },

    generate_rectangle: function(
      { position: [x1, y1, x2, y2], style, resizable, moveable },
      extra_info
    ) {
      const self = this;
      const result = new ResizableRectangle(
        {
          shape: {
            x: parseInt(x1),
            y: parseInt(y1),
            width: parseInt(x2 - x1),
            height: parseInt(y2 - y1),
          },
          style: { fill: "transparent", stroke: "black", ...style },
          scale: self.scale,
          resizable,
          moveable,
          disable: this.active_step == 5,
        },
        {
          ...extra_info,
          shape: { x1, y1, x2, y2 },
          bounds: [
            self.current_image_info.width,
            self.current_image_info.height,
          ],
        },
        self.click_one_component,
        self.resize_handler
      );
      return result;
    },

    final_save() {
      const self = this;
      this.$confirm(
        "此操作将永久改变题目划分，并立即作用到相应的学生试卷上，是否继续？",
        "提示",
        {
          type: "warning",
        }
      ).then(() => {
        self.save();
      });
    },
    save() {
      const fucker = this.data.fucker;
      const data = reverse_convert_single_page(this.actual_struct);
      const exam_id = this.data.exam_id;
      const image_infos = this.data.image_infos;
      const self = this;
      const auth = Cookies.get("platform@token");
      axios
        .post(this.host + "/set", { data, fucker, exam_id, image_infos })
        .then((d) => {
          if (d.data.code == "305") {
            Message.error("该试卷已被驳回无法保存");
          } else if (d.data.code == "300") {
            const msg = d.data.message;
            const msgHtml = "<p>" + msg.join("</p><p>") + "</p>";
            this.$alert(msgHtml, "警告", {
              dangerouslyUseHTMLString: true,
            });
          } else if (d.data.code == "200") {
            axios
              .post(
                this.ahost + "/managePlatform/examPosition/reject",
                { examId: exam_id, answerList: JSON.stringify(d.data.new_al) },
                {
                  headers: {
                    // Authorization: auth,
                  },
                }
              )
              .then((res) => {
                if (res.data.code == "E-111405") {
                  Message.error(res.data.message);
                } else if (res.data.code == "E-040019") {
                  Message.error(res.data.message);
                } else {
                  Message.success("保存成功");
                }
              });
          } else {
            Message.error("服务器内部出现错误");
          }
        });
    },
    tmp_save() {
      const fucker = this.data.fucker;
      const data = reverse_convert_single_page(this.actual_struct);
      const exam_id = this.data.exam_id;
      const image_infos = this.data.image_infos;
      axios
        .post(this.host + "/tmpset", { data, fucker, exam_id, image_infos })
        .then((d) => {
          if (d.data.code == "305") {
            Message.error("该试卷已被驳回无法保存");
          } else {
            Message.success("暂存成功");
          }
        });
    },
    resize_handler: function(extra_info, shape) {
      const {
        level,
        dati_num,
        xiaoti_num,
        third_xiaoti_num,
        answer_index,
        area_index,
        xiaoti_id,
        dati_id,
        third_xiaoti_id,
        tuka_sno_index,
      } = extra_info;
      const { x, y, width, height } = shape;
      if (level == 1) {
        this.actual_struct[dati_id].areas[area_index].position = [
          x,
          y,
          x + width,
          y + height,
        ];
      } else if (level == 2) {
        this.actual_struct[dati_id].xiaotis[xiaoti_id].areas[
          area_index
        ].position = [x, y, x + width, y + height];
      } else if (level == 100) {
        if (third_xiaoti_id) {
          // 三级小题的答案
          const tmp = this.actual_struct[dati_id].xiaotis[xiaoti_id].xiaotis[
            third_xiaoti_id
          ].answer_positions[answer_index - 1];
          this.actual_struct[dati_id].xiaotis[xiaoti_id].xiaotis[
            third_xiaoti_id
          ].answer_positions[answer_index - 1].position = [
            x,
            y,
            x + width,
            y + height,
            tmp && tmp.length > 4 ? tmp[4] : 0,
          ];
        } else {
          // 二级小题的答案
          const tmp = this.actual_struct[dati_id].xiaotis[xiaoti_id]
            .answer_positions[answer_index - 1];
          this.actual_struct[dati_id].xiaotis[xiaoti_id].answer_positions[
            answer_index - 1
          ].position = [
            x,
            y,
            x + width,
            y + height,
            tmp && tmp.length > 4 ? tmp[4] : 0,
          ];
        }
      } else if (level == 53) {
        //51 常规学号 ； a 52 涂卡学号  b 53 条形码学号 ; c54 二维码学号  41 分栏线
        this.data.fucker.extra_info.student_no_b = {
          position: [x, y, x + width, y + height],
        };
      } else if (level == 51) {
        this.data.fucker.extra_info.student_no.position = [
          x,
          y,
          x + width,
          y + height,
        ];
        if (width >= height) {
          this.data.fucker.extra_info.student_no.horizontal = "true";
          this.no_horizontal = "true";
        } else {
          this.data.fucker.extra_info.student_no.horizontal = "false";
          this.no_horizontal = "false";
        }
      } else if (level == 54) {
        this.data.fucker.extra_info.student_no_c = {
          position: [x, y, x + width, y + height],
        };
      } else if (level == 55) {
        //学号姓名遮罩
        //移动当前遮罩位置改变当前的position
        const { index } = this.getCurrentIndex();
        this.data.fucker.extra_info.student_no_shade[index] = {
          index: this.current_index,
          position: [x, y, x + width, y + height],
        };
      } else if (level == 609) {
        this.data.fucker.extra_info.name = {
          position: [x, y, x + width, y + height],
        };
      } else if (level == 61) {
        this.data.fucker.extra_info.chengjikuang = {
          position: [x, y, x + width, y + height],
        };
      } else if (level == 62) {
        this.data.fucker.extra_info.quekao = {
          position: [x, y, x + width, y + height],
        };
      } else if (level == 633) {
        const fi = this.data.fucker.extra_info.paperScore.file_index;
        this.data.fucker.extra_info.paperScore = {
          position: [x, y, x + width, y + height],
          file_index: fi,
        };
      } else if (level == 81) {
        this.actual_struct[dati_id].xiaotis[
          xiaoti_id
        ].score_position.position = [x, y, x + width, y + height];
      } else if (level == 52) {
        this.data.fucker.extra_info.student_no_a.positions[tuka_sno_index] = [
          x,
          y,
          x + width,
          y + height,
        ];
      }
    },
    click_one_component: function(target) {
      this.clear_zr_mouse_event();
      this.focus_component = target;
      const extra_info = target.extra_info,
        opts = target.opts;
      var c_xiaoti = 0;
      if (
        extra_info["dati_id"] &&
        extra_info["xiaoti_id"] &&
        this.actual_struct[extra_info["dati_id"]] &&
        this.actual_struct[extra_info["dati_id"]].xiaotis
      ) {
        c_xiaoti = this.actual_struct[extra_info["dati_id"]].xiaotis[
          extra_info["xiaoti_id"]
        ].auto;
      }
      this.current_question = {
        ...extra_info,
        autoFlag: c_xiaoti,
      };
      this.init_question_exhibition_2();
    },

    getUrlKey: function(name) {
      return (
        decodeURIComponent(
          (new RegExp("[?|&]" + name + "=" + "([^&;]+?)(&|#|;|$)").exec(
            location.href
          ) || ["", ""])[1].replace(/\+/g, "%20")
        ) || null
      );
    },
  },
  components: {},
};
</script>

<style>
body,
html {
  height: 100%;
  min-width: 1366px;
}

@media screen and (min-width: 1366px) and (max-width: 1600px) {
  * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-size: 12px !important;
  }
}
@media screen and (min-width: 1600px) {
  * {
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    font-size: 16px !important;
  }
}
.canvas-wrapper {
  overflow: hidden;
}
.canvas-wrapper_wrapper {
  position: absolute;
}
#app {
  height: 100%;
  background: white;
}
.canvas-wrapper_mother {
  background-image: linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.25) 25%,
      transparent 0,
      transparent 75%,
      rgba(0, 0, 0, 0.25) 0
    ),
    linear-gradient(
      45deg,
      rgba(0, 0, 0, 0.25) 25%,
      transparent 0,
      transparent 75%,
      rgba(0, 0, 0, 0.25) 0
    );
  background-color: #eee;
  background-size: 30px 30px;
  background-position: 0 0, 15px 15px;
  height: calc(100% - 35px);
}
.my-badge {
  background-color: #f56c6c;
  border-radius: 8px;
  color: #fff;
  font-size: 10px !important;
  width: 20px;
  height: 16px;
  text-align: center;
  white-space: nowrap;
  position: absolute;
  top: -8px;
  right: -8px;
  display: none;
  /* -webkit-transform: translateY(-50%) translateX(100%); */
  /* -webkit-transform: translateY(-50%) translateX(100%); */
}
.el-main {
  padding: 10px !important;
  overflow: visible !important;
}
.span-tool-bar {
  display: inline-block;
  padding: 10px;
  cursor: pointer;
}
.span-tool-bar:hover {
  background: rgb(73, 73, 73);
  color: white;
}
#canvas1 {
  background-size: 100%;
}
.div-dati-single {
  padding: 3px 0px 3px 0px;
  width: 45px;
  text-align: center;
  border: 1px solid #ccc;
  position: relative;
  display: inline-block;
  /* width: 50px; */
  text-align: center;
  cursor: pointer;
  border-radius: 5px;
}
.div-dati-single-title {
  padding: 5px 0px 5px 0px;
  margin: 5px;
  cursor: pointer;
  position: relative;
}
.div-dati-single-focus {
  background: #2bb4a4 !important;
  border-color: #2bb4a4 !important;
  border-radius: 5px;
  color: white;
}
.vue-contextmenuName-demo {
  padding: 5px;
  border: 1px solid #ccc;
}
.btn-wrapper-simple,
.no-child-btn {
  height: auto !important;
}
.context-menu-list:hover {
  background: #bbb !important;
}
abc {
  font-weight: bold;
  color: red;
}
.p-title {
  padding: 0px 0px 20px 10px;
}
.p-title-1 {
  cursor: pointer;
}
.div-dati-single-wrapper {
  display: inline-block;
  widows: 50px;
  margin: 5px 5px 5px 5px;
  position: relative;
}
.div-dati-single-wrapper:hover .my-badge {
  display: inline-block;
  animation: myfirst1 200ms 1;
  animation-timing-function: linear;
}
@keyframes myfirst1 {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(45deg);
  }
  50% {
    transform: rotate(90deg);
  }
  75% {
    transform: rotate(135deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
.div-difference-title {
  display: table-cell;
  padding: 10px;
}
.fucker-main {
  padding: 0px !important;
}
.fucker-aside-change-size {
  height: 100%;
  width: 100%;
}
.fucker-aside-change-size:hover {
  cursor: col-resize;
  background: #ccc;
}
.div-big-title,
.div-big-title-1 {
  display: inline-block;
  border: 1px solid #2bb4a4;
  padding: 5px 30px 5px 30px;
  border-radius: 10px;
  margin-right: 50px;
  position: relative;
  cursor: pointer;
}
.div-big-title:after {
  content: "";
  position: absolute;
  width: 50px;
  height: 2px;
  right: -50px;
  top: 15px;
  background: #ccc;
}
.div-big-title-wrapper {
  display: block;
  padding-top: 10px;
}
.div-big-title-focus {
  background: #2bb4a4 !important;
  color: white !important;
}
.div-1 {
  position: relative;
  display: inline-block;
}
.div-1_1 {
  float: right;
  margin-top: 5px;
}
.div-2 {
  display: inline-block;
  position: absolute;
  right: 0px;
}
.div-3 {
  display: inline-block;
}
.span-1 {
  display: inline-block;
  padding: 0px 5px;
  font-size: 20px;
  color: #ccc;
}
.div-3-1 {
  display: inline-block;
  border: 1px solid #114e47;
  padding: 5px 15px;
  background: white;
  font-size: 12px;
  border-radius: 5px;
  cursor: pointer;
}
.div-3-1:hover {
  background: rgba(75, 177, 166, 0.0588235294117647);
  color: #114e47;
}
.span-btn {
  display: inline-block;
  padding: 8px;
  border: 1px solid #ccc;
  margin: 0px 10px 0px 10px;
  cursor: pointer;
}
.span-btn-focus {
  background: #ccc;
  color: white;
}
.div-snapshot {
  position: fixed;
  bottom: 0px;
  right: 20%;
  border: 1px solid #ccc;
}
.div-snapshot-pointer {
  border: 1px solid red;
  position: absolute;
}
.abc {
  color: #2bb4a4;
  top: 10px;
  right: 10px;
  float: right;
  margin-right: 10px;
}
.ppp-1 {
  margin: 5px;
  padding: 5px;
  background: #eee;
}
.ppp-1-span-2 {
  color: #2bb4a4;
}
.el-divider--horizontal {
  margin: 10px 0px !important;
}
.div-difference-content-p {
  padding-left: 10px;
  cursor: pointer;
}
.div-difference-content-p:hover {
  background: #ccc;
}
</style>
