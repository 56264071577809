/**
 * 整个页面通用的一些方法，比如上方进度的按钮点击事件等等
 */
import zrender from "zrender";
import { Message } from "element-ui";
export default {
  refreshSnapshot() {
    //右下角小地图的位置
    const { clientHeight, clientWidth } = document.getElementsByClassName(
      "canvas-wrapper"
    )[0];
    let width = clientWidth / this.scale,
      height = clientHeight / this.scale;
    width =
      width < this.current_image_info.width
        ? width / 30
        : this.current_image_info.width / 30;
    height =
      height < this.current_image_info.height
        ? height / 30
        : this.current_image_info.height / 30;
    let { top, left } = this.wrapper_move;
    (top = -top), (left = -left);
    this.snap_shot_point = {
      top: top / this.scale / 30,
      left: left / 30 / this.scale,
      width: width,
      height: height,
    };
  },
  init_div_snapshot_pointer() {
    // 小地图相关事件
    const self = this;
    let x1, y1;
    // const mother = document.getElementById("div-snapshot-pointer-b");

    document
      .getElementById("div-snapshot-pointer-a")
      .addEventListener("mousedown", (e) => {
        const motherWidth = this.current_image_info.width / 30;
        const motherHeight = this.current_image_info.height / 30;
        (x1 = e.clientX), (y1 = e.clientY);
        const { top, left, width, height } = self.snap_shot_point;
        const hhh = (e1) => {
          const x2 = e1.clientX,
            y2 = e1.clientY;
          // log({...self.snap_shot_point,top:top+y2-y1,left:left+x2-x1})
          // const newTop
          const halfW = width / 2;
          const halfH = height / 2;
          let newLeft = left + x2 - x1;
          let newTop = top + y2 - y1;
          if (newLeft < -halfW) {
            newLeft = -halfW;
          } else if (newLeft + width > motherWidth + halfW) {
            newLeft = motherWidth - width + halfW;
          }

          if (newTop < -halfH) {
            newTop = -halfH;
          } else if (newTop + height > motherHeight + halfH) {
            newTop = motherHeight - height + halfH;
          }
          self.snap_shot_point = {
            width,
            height,
            top: newTop,
            left: newLeft,
          };
          //   console.log(newLeft, motherWidth - width);
          self.wrapper_move = {
            top: -newTop * 30 * self.scale,
            left: -newLeft * 30 * self.scale,
          };
        };
        document
          .getElementById("div-snapshot-pointer-a")
          .addEventListener("mousemove", hhh);
        document
          .getElementById("div-snapshot-pointer-a")
          .addEventListener("mouseup", () => {
            document
              .getElementById("div-snapshot-pointer-a")
              .removeEventListener("mousemove", hhh);
          });
      });
  },
  init_window_scale_and_move_and_move_event: function() {
    // 初始化页面的放大和移动的键盘设置
    // Ctrl + 上箭头 表示放大;Ctrl + 下箭头表示缩小
    // 上/下/左/右箭头表示往相应方向移动
    const self = this;

    document.getElementsByClassName(
      "canvas-wrapper"
    )[0].onmousewheel = function(e) {
      if (e.wheelDelta && !self.addFormVisible) {
        //判断浏览器IE，谷歌滑轮事件
        if (e.wheelDelta > 0) {
          //当滑轮向上滚动时
          self.scale_page(0.1);
        }
        if (e.wheelDelta < 0) {
          //当滑轮向下滚动时
          self.scale_page(-0.1);
        }
      }
    };

    document.onkeydown = function(e) {
      var keyCode = e.keyCode || e.which || e.charCode;
      var ctrlKey = e.ctrlKey || e.metaKey;
      if (self.addFormVisible) {
        // 在显示表单的情况下不响应任何事件
        return;
      }

      if (ctrlKey && keyCode == 38) {
        self.scale_page(0.1);
      } else if (ctrlKey && keyCode == 40) {
        self.scale_page(-0.1);
      } else if (keyCode == 38) {
        self.wrapper_move.top = self.wrapper_move.top - 200;
      } else if (keyCode == 40) {
        self.wrapper_move.top = self.wrapper_move.top + 200;
      } else if (keyCode == 39) {
        self.wrapper_move.left = self.wrapper_move.left + 200;
      } else if (keyCode == 37) {
        self.wrapper_move.left = self.wrapper_move.left - 200;
      } else if (keyCode == 46 || keyCode == 8) {
        self.deleteItem(self.focus_component.extra_info);
        // self.group.remove(self.focus_component);
      } else if (ctrlKey && keyCode == 67) {
        if (self.focus_component.extra_info.level == 52) {
          const { x, y, height, width } = self.focus_component.opts.shape;
          self.data.fucker.extra_info.student_no_a.positions.push([
            x + 20,
            y + 20,
            x + 20 + width,
            y + 20 + height,
          ]);
          self.show_all_student_no();
        }
      }
    };
  },
  scale_page: function(gap) {
    if (
      parseInt(this.scale * 100 + gap * 100) / 100 < 0.1 ||
      parseInt(this.scale * 100 + gap * 100) / 100 > 1
    ) {
      return;
    } else {
      const old_scale = this.scale + 0;
      this.scale = parseInt(this.scale * 100 + gap * 100) / 100;
      this.group.eachChild((item) => {
        item.refresh_scale(this.scale);
      });
      this.zr.resize({
        width: this.current_image_info.width * this.scale,
        height: this.current_image_info.height * this.scale,
      });
      const { top, left } = this.wrapper_move;
      this.wrapper_move = {
        top: (top * this.scale) / old_scale,
        left: (left * this.scale) / old_scale,
      };
      this.refreshSnapshot();
    }
  },
  init_fixed_area: function(index) {
    this.fixed_area = {
      width: this.data.image_infos[index].width * this.scale,
      height: this.data.image_infos[index].height * this.scale,
    };
  },
  init_page: function() {
    //初始化整个页面，需要处理的
    const index = this.current_index;
    zrender.dispose(this.zr);
    this.current_image_info = this.data.image_infos[index];
    this.zr = zrender.init(document.getElementById("canvas1"), {
      width: this.current_image_info.width * this.scale,
      height: this.current_image_info.height * this.scale,
    });
    this.group = new zrender.Group();
    this.zr.add(this.group);
    // this.init_move_relative_event();
  },
  reset() {
    this.wrapper_move = { top: 0, left: 0 };
    this.scale_page(0.4 - this.scale);
  },
  to(step) {
    if (
      (this.question_difference.d1 && this.question_difference.d1.length) ||
      (this.question_difference.d2 && this.question_difference.d2.length)
    ) {
      Message.error("题目划分出错");
      return;
    }
    this.active_step = step;
    this.current_index = 0;
    this.max_index = this.data.image_infos.length - 1;
    if (step == 3||step == 2||step == 5) {
      this.step_in_step_3 = 1;
    }

    this.initBackground();
    this.afterChangePage();
  },

  previousPage() {
    this.beforeChangePage();
    if (this.current_index == 0) {
      this.current_index = this.max_index;
    } else {
      this.current_index = this.current_index - 1;
    }
    this.initBackground();
    this.afterChangePage();
    this.anamite_move({ top: 0, left: 0 });
  },
  nextPage() {
    this.beforeChangePage();
    if (this.current_index == this.max_index) {
      this.current_index = 0;
    } else {
      this.current_index = this.current_index + 1;
    }
    this.initBackground();
    this.afterChangePage();
    this.anamite_move({ top: 0, left: 0 });
  },
  beforeChangePage() {
    //换页之前的工作，大部分是保存当前页的修改结果
  },

  afterChangePage: function() {
    /**
     * 根据不同的情境展示不同的canvas图像
     */
    if (this.active_step == 1) {
      this.show_lines();
    } else if (this.active_step == 2) {
      this.show_all_student_no();
    } else if (this.active_step == 3) {
      // 进“框选款选大小题及答案区”页面之后有三个工作：
      // 1. 画出第一面的所有大题
      // 2. 比较题目结构与答案库的区别
      // 3. 显示右边的大小题导航栏
      this.render_all_datis();
      this.compare_questions();
      this.init_question_exhibition_2();
    } else if (this.active_step == 4) {
      this.show_all_other_flags();
    } else if (this.active_step == 5) {
      const tmp_lines = [];
      const mmapper = { 0: "单栏", 1: "双栏", 2: "三栏" };
      for (let i = 0; i < this.data.image_infos.length; i++) {
        tmp_lines.push(
          "第" +
            (i + 1) +
            "页-" +
            mmapper[this.data.image_infos[i].lines.length]
        );
      }
      this.yulanstatus_line = tmp_lines.join("  ;  ");
      this.refresh_other_flag_status();
      this.refresh_all_student_no_status();
      this.clear_except_background();
      this.show_all_student_no(true);
      this.show_all_other_flags(true);
      this.render_all_datis(true);
      this.show_lines(true);
    }
  },
  move_image: function() {
    const self = this;
    if (!this.page_moveable) {
      this.zr.on("mousedown", (e) => {
        let [x1, y1] = [e.offsetX, e.offsetY];
        this.zr.on("mousemove", (e) => {
          let [x2, y2] = [e.offsetX, e.offsetY];
          self.wrapper_move.top = self.wrapper_move.top + y2 - y1;
          self.wrapper_move.left = self.wrapper_move.left + x2 - x1;
          self.refreshSnapshot();
        });
      });
      this.zr.on("mouseup", (e) => {
        self.zr.off("mousemove");
        // self.clear_zr_mouse_event();
      });
    } else {
      self.clear_zr_mouse_event();
    }
    this.page_moveable = !this.page_moveable;
  },
};
