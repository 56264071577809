import { Message } from "element-ui";
import uuidv1 from "uuid/v1";
import { ResizableRectangle } from "../components/rectangle.js";
import {
  convert_single_page,
  find_dati_id,
  find_xiaoti_id,
  reverse_convert_single_page,
  special_map,
} from "../components/static_data.js";
import axios from "./axios.js";

const find_series = (arr) => {
  //将数组中连续的元素放到一起
  if (arr.length == 1) {
    return [arr];
  }
  const new_a = arr.sort((a, b) => a - b);
  const result = [[]];
  let i = 0;
  for (; i < new_a.length - 1; i++) {
    let c = new_a[i];
    let n = new_a[i + 1];
    if (n - c == 1) {
      let last = result.pop();
      last.push(c);
      result.push(last);
    } else {
      let last = result.pop();
      last.push(c);
      result.push(last);
      result.push([]);
    }
  }
  let last = result.pop();
  last.push(new_a[i]);
  result.push(last);
  return result;
};

const find_question_series_2 = (arr) => {
  // 寻找题目的连续缺失 arr是 [1.1, 1.2, 1.2.1, 1.2.2] 这样的数组
  const arr1 = [],
    arr2 = [];
  arr.forEach((item) => {
    if (item.split(".").length == 2) {
      arr1.push(item);
    } else {
      arr2.push(item);
    }
  });
  const r1 = find_question_series(arr1, 2);
  const r2 = find_question_series(arr2, 100);
  return r1.concat(r2).sort();
};
const find_question_series_3 = (arr) => {
  // 寻找答案的连续缺失 arr是 [1.1.1, 1.2.1, 1.3.1.1, 1.3.1.2] 这样的数组
  const arr1 = [],
    arr2 = [];
  arr.forEach((item) => {
    if (item.split(".").length == 3) {
      arr1.push(item);
    } else {
      arr2.push(item);
    }
  });
  const r1 = find_question_seriesx(arr1, 100);
  const r2 = find_question_seriesx(arr2, 2);
  return r1.concat(r2).sort();
};

const find_question_seriesx = (arr, level) => {
  //level = 2 的话，那么arr是一个[1.1.1.1，1.2.1.1]这样的数组
  // level = 100的话，arr是[1.1.1,1.1.2]这样的数组
  const mapper = {};
  arr.forEach((item) => {
    let a = item.split(".");
    let key = 0;
    if (level == 2) {
      key = a.slice(0, 3).join(".");
    } else if (level == 100) {
      key = a.slice(0, 2).join(".");
    }
    if (!mapper[key]) {
      mapper[key] = [];
    }
    mapper[key].push(parseInt(a.pop()));
  });
  const result = [];
  for (let key in mapper) {
    let tmp = find_series(mapper[key]);
    let ccc = "";
    if (level == 2) {
      ccc =
        "第" + key.replace(".", "大题-第").replace(".", "小题-第") + "小题-";
    } else if (level == 100) {
      ccc = "第" + key.replace(".", "大题-第") + "小题-";
    }
    for (let i = 0; i < tmp.length; i++) {
      let tmp1 = tmp[i];

      if (tmp1.length == 1) {
        ccc += "第" + tmp1[0] + "空，";
      } else {
        let a = tmp1[0];
        let b = tmp1.pop();
        ccc += "第" + a + "~" + b + "空，";
      }
    }
    ccc = ccc.substr(0, ccc.length - 1);
    result.push(ccc);
  }
  return result;
};

const find_question_series = (arr, level) => {
  //level = 2 的话，那么arr是一个[1.1，1.2]这样的数组
  // level = 100的话，arr是[1.1.1,1.1.2]这样的数组
  const mapper = {};
  arr.forEach((item) => {
    let a = item.split(".");
    let key = 0;
    if (level == 2) {
      key = a[0];
    } else if (level == 100) {
      key = a.slice(0, 2).join(".");
    }
    if (!mapper[key]) {
      mapper[key] = [];
    }
    mapper[key].push(parseInt(a.pop()));
  });
  const result = [];
  for (let key in mapper) {
    let tmp = find_series(mapper[key]);
    let ccc = "";
    if (level == 2) {
      ccc = "第" + key + "大题-";
    } else if (level == 100) {
      ccc = "第" + key.replace(".", "大题-第") + "小题-";
    }
    for (let i = 0; i < tmp.length; i++) {
      let tmp1 = tmp[i];

      if (tmp1.length == 1) {
        ccc += "第" + tmp1[0] + "小题，";
      } else {
        let a = tmp1[0];
        let b = tmp1.pop();
        ccc += "第" + a + "~" + b + "小题，";
      }
    }
    ccc = ccc.substr(0, ccc.length - 1);
    result.push(ccc);
  }
  return result;
};

const count_map_length = (obj) => {
  let i = 0;
  for (let k in obj) {
    i++;
  }
  return i;
};

export default {
  todati() {
    this.step_in_step_3 = 1;
    this.current_index = 0;
    this.current_question = {
      level: 1,
      dati_id: undefined,
      xiaoti_id: undefined,
      dati_num: 0,
      xiaoti_num: 0,
      answer_index: 0,
    };
    this.initBackground();
    this.afterChangePage();
  },
  toxiaoti() {
    if (
      this.step_in_step_3 == 1 &&
      (this.question_difference.d1.length || this.question_difference.d2.length)
    ) {
      Message.error("题目划分出错");
      return;
    }
    this.current_index = 0;
    this.step_in_step_3 = 2;
    this.caculatesinglexiaoti();
    this.current_question = {
      level: 1,
      dati_id: undefined,
      xiaoti_id: undefined,
      dati_num: 0,
      xiaoti_num: 0,
      answer_index: 0,
    };
    this.initBackground();
    this.afterChangePage();
  },

  caculatesinglexiaoti() {
    // 当某个大题中仅有一道小题，但是还没有的时候，自动将该小题加上
    try {
      let sp = this.actual_struct;
      for (let dati_key in sp) {
        let dati = sp[dati_key];
        if (!dati.xiaotis || count_map_length(dati.xiaotis) == 0) {
          if (
            count_map_length(this.answer_list[dati["dati_num"]].xiaotis) == 1
          ) {
            const xid = "1-" + uuidv1();
            dati.xiaotis = {};
            const new_a = [];
            for (let area_index in dati.areas) {
              let b = { ...dati.areas[area_index] };
              let [x1, y1, x2, y2] = b.position;
              b.position = [x1 + 10, y1 + 10, x2 - 10, y2 - 10];
              new_a.push(b);
            }
            dati.xiaotis[xid] = {
              id: xid,
              answer_positions: [],
              pointer_positions: [],
              xiaoti_num: 1,
              areas: new_a,
            };
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  },

  changeIfShowAnswerText() {
    this.clear_except_background();
    if (this.active_step == 5) {
      this.to(5);
    } else {
      this.render_all_datis();
    }
  },

  todaanqu() {
    if (
      this.question_difference.d1.length ||
      this.question_difference.d2.length
    ) {
      Message.error("题目划分出错");
      return;
    }

    this.current_index = 0;
    this.step_in_step_3 = 3;
    this.current_question = {
      level: 1,
      dati_id: undefined,
      xiaoti_id: undefined,
      dati_num: 0,
      xiaoti_num: 0,
      answer_index: 0,
    };
    this.initBackground();
    this.afterChangePage();
  },
  onAddXiaotiDatika() {
    this.add_level = 71;
    this.clear_except_background();
    this.init_draw_relative_event();
  },
  closeonAddXiaotiDatika() {
    this.addxiaotitype = "n";
    this.group.remove(this.add_tmp_element);
    try {
      this.$refs["addForm"].resetFields();
    } catch (e) {
      console.error(e);
    }
  },

  onSubmit() {
    //添加答题卡小题的确定按钮回调
    this.form.url = this.current_image_info.url;
    this.form.xiaoti_nums = [];
    if (this.form.type1 == "1") {
      let begin = parseInt(this.form.begin),
        end = parseInt(this.form.end);
      for (let i = begin; i <= end; i++) {
        this.form.xiaoti_nums.push(i);
      }
    } else {
      const nums = this.form.nums;
      for (let i = 0; i < nums; i++) {
        this.form.xiaoti_nums.push(parseInt(this.form["nnn" + i]));
      }
    }
    const { x, y, width, height } = this.add_tmp_element.opts.shape;
    this.form.position = [x, y, x + width, y + height];
    this.form.file_index = this.current_index;
    const { dati_id } = this.current_question;
    const self = this;
    axios.post(this.host + "/get_datika", this.form).then((d) => {
      const data = d.data;
      if (data.message != "success") {
        Message.error("获取答题卡发生错误");
        return;
      }
      const tmp = self.actual_struct[dati_id].xiaotis;
      self.actual_struct[dati_id].xiaotis = { ...tmp, ...data.data };
      self.addFormVisible = false;
      self.clear_except_background();
      self.init_question_exhibition_2();
      self.fucker_render_one_dati(self.actual_struct[dati_id]);
      self.compare_xiaoti();
      self.form = {
        nums: 5,
        begin: 1,
        end: 5,
        type1: "1",
        type2: "1",
        position: undefined,
      };
    });
  },
  changeDatiNum(a) {
    const { dati_id } = this.current_question;
    this.actual_struct[dati_id].dati_num = a;
    this.init_question_exhibition_2();
  },
  changeXiaotiNum(a) {
    const { dati_id, xiaoti_id } = this.current_question;
    this.actual_struct[dati_id].xiaotis[xiaoti_id].xiaoti_num = a;
    this.init_question_exhibition_2();
  },
  changeDatikaStatus(a) {
    const { dati_id, xiaoti_id } = this.current_question;
    this.actual_struct[dati_id].xiaotis[xiaoti_id].is_datika = a == "1";
    this.init_question_exhibition_2();
  },
  compare_dati() {
    const datis = new Set(this.questions.datis);
    const current_datis = new Set();
    const item = this.actual_struct;
    for (let dati_id in item) {
      let dati = item[dati_id];
      current_datis.add(dati.dati_num + "");
    }
    // 画出来，但是答案库不存在的大题
    const d1 = [...current_datis].filter((item) => !datis.has(item));
    // 答案库存在，但是没有画出来的大题
    const d2 = [...datis].filter((item) => !current_datis.has(item));
    this.question_difference = {
      level: 1,
      d1: d1.map((i) => `第${i}大题`),
      d2: d2.map((i) => `第${i}大题`),
    };
  },
  compare_xiaoti() {
    const xiaotis = new Set(this.questions.xiaotis);
    const current_xiaotis = new Set();
    const item = this.actual_struct;
    for (let dati_id in this.actual_struct) {
      let dati = item[dati_id];
      let dati_num = dati.dati_num;
      for (let xiaoti_id in dati.xiaotis) {
        let xiaoti = dati.xiaotis[xiaoti_id];
        let xiaoti_num = xiaoti.xiaoti_num;
        current_xiaotis.add(`${dati_num}.${xiaoti_num}`);
        if (xiaoti.xiaotis) {
          for (let t_xiaoti_id in xiaoti.xiaotis) {
            const t_xiaoti_num = xiaoti.xiaotis[t_xiaoti_id].xiaoti_num;
            current_xiaotis.add(`${dati_num}.${xiaoti_num}.${t_xiaoti_num}`);
          }
        }
      }
    }
    const d1 = [...current_xiaotis].filter((item) => !xiaotis.has(item));
    const d2 = [...xiaotis].filter((item) => !current_xiaotis.has(item));
    this.question_difference = {
      level: 2,
      d1: find_question_series_2(d1), //d1.map(i => "第" + i.replace(".", '大题第') + "小题"),
      d2: find_question_series_2(d2), //d2.map(i => "第" + i.replace(".", '大题第') + "小题"),
    };
  },

  guess_lacked_xiaoti_num() {
    // 为添加小题猜测可能应该添加的小题题号
    const { dati_num: dn } = this.current_question;
    const xiaotis = new Set(
      this.questions.xiaotis.filter((item) => item.startsWith(dn + "."))
    );
    const current_xiaotis = new Set();
    const item = this.actual_struct;
    for (let dati_id in this.actual_struct) {
      let dati = item[dati_id];
      let dati_num = dati.dati_num;
      if (dati_num != dn) {
        continue;
      }
      for (let xiaoti_id in dati.xiaotis) {
        let xiaoti = dati.xiaotis[xiaoti_id];
        let xiaoti_num = xiaoti.xiaoti_num;
        current_xiaotis.add(`${dati_num}.${xiaoti_num}`);
      }
    }
    const d2 = [...xiaotis].filter((item) => !current_xiaotis.has(item));
    if (d2.length > 0) {
      let tmp = d2.map((item) => parseInt(item.split(".")[1]));
      return tmp.sort((a, b) => a - b)[0];
    } else {
      return undefined;
    }
  },

  guess_lacked_dati_num() {
    //猜测缺少的大题题号
    const datis = new Set(this.questions.datis);
    const current_datis = new Set();
    const item = this.actual_struct;
    for (let dati_id in this.actual_struct) {
      let dati = item[dati_id];
      current_datis.add(dati.dati_num + "");
    }
    const d2 = [...datis].filter((item) => !current_datis.has(item));
    if (d2.length > 0) {
      return d2.sort((a, b) => a - b)[0];
    } else {
      return undefined;
    }
  },

  compare_answers() {
    const answers = new Set(this.questions.answers);
    const current_answers = new Set();
    const item = this.actual_struct;
    for (let dati_id in this.actual_struct) {
      let dati = item[dati_id];
      let dati_num = dati.dati_num;
      for (let xiaoti_id in dati.xiaotis) {
        let xiaoti = dati.xiaotis[xiaoti_id];
        let xiaoti_num = xiaoti.xiaoti_num;
        for (let i = 0; i < xiaoti.answer_positions.length; i++) {
          current_answers.add(`${dati_num}.${xiaoti_num}.${i + 1}`);
        }
        if (xiaoti.xiaotis) {
          for (let t_xiaoti_id in xiaoti.xiaotis) {
            const t_xiaoti = xiaoti.xiaotis[t_xiaoti_id];
            const t_xiaoti_num = t_xiaoti.xiaoti_num;
            for (let i = 0; i < t_xiaoti.answer_positions.length; i++) {
              current_answers.add(
                `${dati_num}.${xiaoti_num}.${t_xiaoti_num}.${i + 1}`
              );
            }
          }
        }
      }
    }
    const d1 = [...current_answers].filter((item) => !answers.has(item));
    const d2 = [...answers].filter((item) => !current_answers.has(item));
    this.question_difference = {
      level: 100,
      d1: find_question_series_3(d1), //d1.map(i => "第" + i.replace(".", '大题第').replace(".", '小题第') + "空"),
      d2: find_question_series_3(d2), //d2.map(i => "第" + i.replace(".", '大题第').replace(".", '小题第') + "空")
    };
  },

  compare_questions() {
    if (this.step_in_step_3 == 1) {
      this.compare_dati();
    } else if (this.step_in_step_3 == 2) {
      this.compare_xiaoti();
    } else if (this.step_in_step_3 == 3) {
      this.compare_answers();
    }
  },

  addtengfenkuang() {
    this.add_level = 81;
    this.clear_except_background();
    const { dati_num, dati_id, xiaoti_num, xiaoti_id } = this.current_question;
    this.render_disable_areas(
      this.actual_struct[dati_id].xiaotis[xiaoti_id].areas,
      { level: 2, dati_num, xiaoti_num }
    );
    this.init_draw_relative_event();
  },
  deletetengfenkuang() {
    const { dati_id, xiaoti_id, dati_num } = this.current_question;
    delete this.actual_struct[dati_id].xiaotis[xiaoti_id].score_position;
    this.clear_except_background();
    this.fucker_render_one_xiaoti({
      dati_id,
      dati_num,
      ...this.actual_struct[dati_id].xiaotis[xiaoti_id],
    });
    this.current_question = { ...this.current_question, score_position: false };
  },
  onAddDati: function() {
    //点击添加大题
    this.add_level = 1;
    this.clear_except_background();
    this.init_draw_relative_event();
  },
  onAddXiaoti: function() {
    this.add_level = 2;
    this.clear_except_background();
    this.render_disable_areas(
      this.actual_struct[this.current_question.dati_id].areas,
      { level: 1, dati_num: this.current_question.dati_num }
    );
    this.init_draw_relative_event();
  },
  onAddAnswer: function() {
    this.add_level = 100;
    this.clear_except_background();
    const {
      dati_num,
      dati_id,
      xiaoti_num,
      xiaoti_id,
      third_xiaoti_id,
      third_xiaoti_num,
    } = this.current_question;
    if (third_xiaoti_id) {
      this.render_disable_areas(
        this.actual_struct[dati_id].xiaotis[xiaoti_id].xiaotis[third_xiaoti_id]
          .areas,
        { level: 3, dati_num, xiaoti_num, third_xiaoti_num }
      );
    } else {
      this.render_disable_areas(
        this.actual_struct[dati_id].xiaotis[xiaoti_id].areas,
        { level: 2, dati_num, xiaoti_num }
      );
      this.render_disable_areas(
        this.actual_struct[dati_id].xiaotis[xiaoti_id].answer_positions,
        { level: 100, dati_num, xiaoti_num }
      );
    }
    this.init_draw_relative_event();
  },

  onAddTuka: function() {
    this.add_level = 101;
    this.clear_except_background();
    const {
      dati_num,
      dati_id,
      xiaoti_num,
      xiaoti_id,
      answer_index,
    } = this.current_question;
    this.render_disable_areas(
      [
        this.actual_struct[dati_id].xiaotis[xiaoti_id].answer_positions[
          parseInt(answer_index) - 1
        ],
      ],
      { level: 100, dati_num, xiaoti_num }
    );
    const file_index = this.current_index;
    this.render_disable_areas(
      this.actual_struct[dati_id].xiaotis[xiaoti_id].answer_positions[
        parseInt(answer_index) - 1
      ].tuka_positions.map((item) => {
        return { position: item, file_index };
      }),
      { level: 101, dati_num, xiaoti_num },
      { fill: "red", opacity: 0.3 }
    );

    this.init_draw_relative_event();
  },

  onAddThirdXiaoti() {
    this.add_level = 3;
    this.clear_except_background();
    const { dati_num, dati_id, xiaoti_num, xiaoti_id } = this.current_question;
    this.render_disable_areas(
      this.actual_struct[dati_id].xiaotis[xiaoti_id].areas,
      { level: 2, dati_num, xiaoti_num }
    );
    this.init_draw_relative_event();
  },

  init_question_exhibition_2() {
    //初始化左侧的大小题、答案区展示区
    //先初始化大题
    //然后根据有无点击初始化相应的东西
    const struct = this.actual_struct;
    const {
      dati_id,
      dati_num,
      xiaoti_id,
      xiaoti_num,
      third_xiaoti_id,
      third_xiaoti_num,
      answer_index,
    } = this.current_question;
    let xiaotis = [],
      third_xiaotis = [],
      answer_positions = [],
      tuka_positions = [];
    // 右边的导航页任何情况下都有大题
    const datis = special_map(struct, (item, dati_id) => {
      return {
        level: 1,
        dati_num: item.dati_num,
        dati_id,
        fileIndexes: new Set(item.areas.map((area) => area.file_index)),
      };
    });

    //在“框选小题中”
    if (
      this.step_in_step_3 == 2 ||
      this.step_in_step_3 == 3 ||
      this.active_step == 5
    ) {
      if (dati_id) {
        const tmp_xiaotis = struct[dati_id].xiaotis;
        xiaotis = special_map(tmp_xiaotis, (item, id) => {
          return {
            fileIndexes: new Set(item.areas.map((area) => area.file_index)),
            level: 2,
            dati_id,
            dati_num,
            xiaoti_id: id,
            xiaoti_num: item.xiaoti_num,
          };
        });
        if (
          xiaoti_id &&
          tmp_xiaotis[xiaoti_id] &&
          tmp_xiaotis[xiaoti_id].xiaotis
        ) {
          third_xiaotis = special_map(
            tmp_xiaotis[xiaoti_id].xiaotis,
            (item, id) => {
              return {
                fileIndexes: new Set(item.areas.map((area) => area.file_index)),
                level: 3,
                dati_id,
                dati_num,
                xiaoti_id,
                xiaoti_num,
                third_xiaoti_id: id,
                third_xiaoti_num: item.xiaoti_num,
              };
            }
          );
        }
      }
    }
    if (this.step_in_step_3 == 3 || this.active_step == 5) {
      if (dati_id && xiaoti_id && third_xiaoti_id) {
        // 说明点击的是三级小题的答案
        const tmp_aps =
          struct[dati_id].xiaotis[xiaoti_id].xiaotis[third_xiaoti_id]
            .answer_positions;
        answer_positions = special_map(tmp_aps, (item, index) => {
          return {
            fileIndexes: new Set([item.file_index]),
            level: 100,
            dati_num,
            xiaoti_num,
            dati_id,
            xiaoti_id,
            answer_index: parseInt(index) + 1,
            answer_id: item.id,
            third_xiaoti_num,
            third_xiaoti_id,
          };
        });
      } else if (dati_id && xiaoti_id) {
        const tmp_aps = struct[dati_id].xiaotis[xiaoti_id].answer_positions;
        answer_positions = special_map(tmp_aps, (item, index) => {
          return {
            fileIndexes: new Set([item.file_index]),
            level: 100,
            dati_num,
            xiaoti_num,
            dati_id,
            xiaoti_id,
            answer_index: parseInt(index) + 1,
            answer_id: item.id,
          };
        });
      }
      if (dati_id && xiaoti_id && answer_index) {
        // 仅会存在于一级小题的涂卡

        const tukk = this.actual_struct[dati_id].xiaotis[xiaoti_id]
          .answer_positions[answer_index - 1]
          ? this.actual_struct[dati_id].xiaotis[xiaoti_id].answer_positions[
              answer_index - 1
            ].tuka_positions
          : [];
        if (tukk != null && tukk != undefined && tukk.length > 0) {
          tuka_positions = special_map(tukk, (item, index) => {
            return {
              level: 101,
              dati_num,
              xiaoti_num,
              dati_id,
              xiaoti_id,
              answer_index,
              tuka_index: parseInt(index) + 1,
            };
          });
        }
      }
    }
    this.list_question = {
      datis,
      xiaotis,
      answer_positions,
      third_xiaotis,
      tuka_positions,
    };
  },

  render_disable_areas(areas, extra_info, style) {
    //用于展示不能移动，不能改变大小的区域
    const self = this;
    const current_index = this.current_index;
    areas.forEach((item, index) => {
      if (item.file_index != current_index) {
        return;
      }
      const {
        position: [x1, y1, x2, y2],
      } = item;
      this.group.add(
        new ResizableRectangle(
          {
            shape: {
              x: parseInt(x1),
              y: parseInt(y1),
              width: parseInt(x2 - x1),
              height: parseInt(y2 - y1),
            },
            style: { fill: "transparent", stroke: "red", ...style },
            scale: self.scale,
            disable: true,
          },
          { ...extra_info, answer_index: index + 1 }
        )
      );
    });
  },
  makeup_mx() {
    const self = this;
    this.$confirm("此操作将利用系统自动识别小题，请谨慎使用！！！", "提示", {
      type: "warning",
    }).then(() => {
      self.actual_makeup_mx();
    });
  },
  actual_makeup_mx() {
    const fucker = this.data.fucker;
    const struct = this.actual_struct;
    const data = reverse_convert_single_page(struct);
    const exam_id = this.data.exam_id;
    const image_infos = this.data.image_infos;
    const self = this;
    axios
      .post(this.host + "/makeup_mx", { data, fucker, exam_id, image_infos })
      .then((d) => {
        if (d.data.msg == "success") {
          self.actual_struct = convert_single_page(d.data.actual_struct);
          self.afterChangePage();
        }
      });
  },
  assemblynormalzone() {
    const self = this;
    this.$confirm(
      "此操作将利用系统自动识别出的答案区，并且将覆盖所有手动添加的答案区，请谨慎使用！！！",
      "提示",
      {
        type: "warning",
      }
    ).then(() => {
      self.actualassemblynormalzone();
    });
  },
  actualassemblynormalzone() {
    const fucker = this.data.fucker;
    const struct = this.actual_struct;
    const data = reverse_convert_single_page(struct);
    const exam_id = this.data.exam_id;
    const image_infos = this.data.image_infos;
    const self = this;
    axios
      .post(this.host + "/assembly_normal_zones", {
        data,
        fucker,
        exam_id,
        image_infos,
      })
      .then((d) => {
        if (d.data.msg == "success") {
          self.actual_struct = convert_single_page(d.data.actual_struct);
          self.afterChangePage();
        }
      });
  },
  clickqueshao(item) {
    console.log(item);
    // 点击缺少的题目
    if (this.step_in_step_3 != 3) {
      return;
    }
    const reg = /[0-9]+/g;
    let numList = item.match(reg);
    if (numList.length <= 2) {
      return;
    }
    const self = this;
    const dati_num = numList[0];
    const xiaoti_num = numList[1];
    let flag = false;
    const ttt = this.actual_struct;
    for (let key in ttt) {
      if (flag) {
        return;
      }
      if (!key.startsWith(dati_num + "-")) {
        continue;
      }
      let dati = ttt[key];
      for (let xiaoti_id in dati.xiaotis) {
        if (flag) {
          return;
        }
        if (!xiaoti_id.startsWith(xiaoti_num + "-")) {
          continue;
        }
        flag = true;
        self.current_question = {
          dati_num,
          dati_id: key,
          level: 2,
          xiaoti_num,
          xiaoti_id,
          aaa: "asdfasdf",
        };
        if (self.current_index != dati.xiaotis[xiaoti_id].areas[0].file_index) {
          self.current_index = dati.xiaotis[xiaoti_id].areas[0].file_index;
          self.initBackground();
        }
        self.afterChangePage();
        self.clear_except_background();
        self.fucker_render_one_xiaoti({
          dati_num,
          dati_id: key,
          ...dati.xiaotis[xiaoti_id],
        });
        self.anamite_move({
          left: dati.xiaotis[xiaoti_id].areas[0].position[0] - 100 / this.scale,
          top: dati.xiaotis[xiaoti_id].areas[0].position[1] - 150 / this.scale,
        });
      }
    }
  },
  anamite_move(end) {
    const { left: l1, top: t1 } = this.wrapper_move;
    let { left: l2, top: t2 } = end;
    l2 = -(l2 * this.scale);
    t2 = -(t2 * this.scale);
    const ms_count = 40;
    const lgap = (l2 - l1) / ms_count;
    const tgap = (t2 - t1) / ms_count;
    const self = this;
    let a = 0;
    let timer = setInterval(() => {
      const { left: l, top: t } = self.wrapper_move;
      if (lgap < 0 && tgap < 0 && l + lgap < l2 && t + tgap < t2) {
        clearInterval(timer);
      } else if (lgap >= 0 && tgap < 0 && l + lgap >= l2 && t + tgap < t2) {
        clearInterval(timer);
      } else if (tgap >= 0 && lgap < 0 && l + lgap < l2 && t + tgap >= t2) {
        clearInterval(timer);
      } else if (tgap >= 0 && lgap >= 0 && t + tgap >= t2 && l + lgap >= l2) {
        clearInterval(timer);
      } else if (a > ms_count + 1) {
        clearInterval(timer);
      }

      self.wrapper_move = { left: l + lgap, top: t + tgap };
      self.snap_shot_point = {
        ...self.snap_shot_point,
        top: -(t + tgap) / this.scale / 30,
        left: -(l + lgap) / 30 / this.scale,
      };
      a += 1;
    }, 1);
  },
  fucker_render_one_dati({ id: dati_id, dati_num, areas, xiaotis }) {
    if (
      this.step_in_step_3 == 1 ||
      this.step_in_step_3 == 2 ||
      this.active_step == 5
    ) {
      const current_index = this.current_index;
      areas.forEach((item, index) => {
        if (item.file_index != current_index) {
          return;
        }
        this.group.add(
          this.generate_rectangle(
            {
              position: item.position,
              style: { stroke: "#8CC540" },
              resizable: this.step_in_step_3 == 1,
            },
            { level: 1, dati_num: dati_num, area_index: index, dati_id }
          )
        );
      });
    }
    if (
      this.step_in_step_3 == 2 ||
      this.step_in_step_3 == 3 ||
      this.active_step == 5
    ) {
      for (let key in xiaotis) {
        this.fucker_render_one_xiaoti({ ...xiaotis[key], dati_num, dati_id });
      }
    }
  },
  fucker_render_one_xiaoti({
    id: xiaoti_id,
    xiaoti_num,
    areas,
    answer_positions,
    dati_id,
    dati_num,
    score_position,
    xiaotis,
  }) {
    const current_index = this.current_index;
    areas.forEach((item, index) => {
      if (item.file_index != current_index) {
        return;
      }
      this.group.add(
        this.generate_rectangle(
          {
            position: item.position,
            style: { stroke: "#F1592A" },
            resizable: this.step_in_step_3 == 2,
          },
          {
            level: 2,
            dati_num: dati_num,
            dati_id,
            xiaoti_num,
            area_index: index,
            xiaoti_id,
          }
        )
      );
    });
    if (score_position) {
      if (score_position.file_index == current_index) {
        this.group.add(
          this.generate_rectangle(
            {
              position: score_position.position,
              style: { stroke: "red" },
              resizable: this.step_in_step_3 == 2,
            },
            { level: 81, dati_id, xiaoti_id, dati_num, xiaoti_num }
          )
        );
      }
    }
    if (xiaotis) {
      for (let id in xiaotis) {
        let third_xiaoti = xiaotis[id];
        this.fucker_render_third_xiaoti({
          third_xiaoti,
          dati_id,
          dati_num,
          xiaoti_id,
          xiaoti_num,
          third_xiaoti_id: id,
          third_xiaoti_num: third_xiaoti.xiaoti_num,
        });
      }
    }

    if (this.step_in_step_3 == 3 || this.active_step == 5) {
      this.fucker_render_answers_in_one_xiaoti({
        answer_positions,
        dati_id,
        dati_num,
        xiaoti_id,
        xiaoti_num,
      });
    }
  },

  fucker_render_third_xiaoti({
    third_xiaoti,
    dati_id,
    dati_num,
    xiaoti_id,
    xiaoti_num,
    third_xiaoti_id,
    third_xiaoti_num,
  }) {
    const current_index = this.current_index;
    third_xiaoti.areas.forEach((item, index) => {
      if (item.file_index != current_index) {
        return;
      }
      this.group.add(
        this.generate_rectangle(
          {
            position: item.position,
            style: { stroke: "#F1592A", fill: "#FFFDF3", opacity: 0.3 },
            resizable: false,
          },
          {
            level: 3,
            dati_num: dati_num,
            dati_id,
            xiaoti_num,
            area_index: index,
            xiaoti_id,
            third_xiaoti_id,
            third_xiaoti_num,
          }
        )
      );
    });
    if (this.step_in_step_3 == 3 || this.active_step == 5) {
      this.fucker_render_answers_in_one_xiaoti({
        answer_positions: third_xiaoti.answer_positions,
        dati_id,
        dati_num,
        xiaoti_id,
        xiaoti_num,
        third_xiaoti_id,
        third_xiaoti_num,
      });
    }
  },

  fucker_render_answers_in_one_xiaoti({
    answer_positions,
    dati_num,
    dati_id,
    xiaoti_num,
    xiaoti_id,
    third_xiaoti_id,
    third_xiaoti_num,
  }) {
    if (answer_positions && answer_positions.length) {
      const current_index = this.current_index;
      var relative_answers = undefined;
      if (third_xiaoti_id != null && third_xiaoti_id != undefined) {
        relative_answers = this.answer_list[dati_num].xiaotis[xiaoti_num]
          .xiaotis[third_xiaoti_num].answer;
      } else {
        relative_answers = this.answer_list[dati_num].xiaotis[xiaoti_num]
          .answer;
      }
      const show_answer_text = this.show_answer_text;
      answer_positions.forEach((item, index) => {
        if (item.file_index != current_index) {
          return;
        }
        this.group.add(
          this.generate_rectangle(
            {
              position: item.position,
              style: { stroke: "green", fill: "green", opacity: 0.5 },
              resizable: this.step_in_step_3 == 3,
              moveable: true,
            },
            {
              level: 100,
              dati_id,
              xiaoti_id,
              dati_num,
              xiaoti_num,
              third_xiaoti_id,
              third_xiaoti_num,
              answer_index: index + 1,
              area_index: 0,
              answer_id: item.id,
              answer_text: show_answer_text ? relative_answers[index] : "",
            }
          )
        );
        if (item.tuka_positions && item.tuka_positions.length > 0) {
          item.tuka_positions.forEach((tp, ind) => {
            this.group.add(
              this.generate_rectangle(
                {
                  position: tp,
                  style: { stroke: "red", fill: "red", opacity: 0.7 },
                  resizable: false,
                  moveable: false,
                },
                {
                  level: 101,
                  dati_id,
                  xiaoti_id,
                  dati_num,
                  xiaoti_num,
                  third_xiaoti_id,
                  third_xiaoti_num,
                  answer_index: index + 1,
                  area_index: 0,
                  answer_id: item.id,
                  tuka_index: ind + 1,
                }
              )
            );
          });
        }
      });
    }
  },
  clickDatiTitle: function({ level, dati_num, dati_id, fileIndexes }) {
    // 点击大题，展示下面的小题
    if (!fileIndexes || fileIndexes.size == 0) {
      return;
    }
    this.current_question = { level: 1, dati_num: dati_num, dati_id: dati_id };
    this.init_question_exhibition_2();
    if (!fileIndexes.has(this.current_index)) {
      this.current_index = Array.from(fileIndexes).sort()[0];
      this.initBackground();
    }
    this.clear_except_background();
    this.fucker_render_one_dati(this.actual_struct[dati_id]);
    try {
      if (this.move_image_after_click) {
        const current_index = this.current_index;
        const [x, y] = this.actual_struct[dati_id].areas.filter(
          (item) => item.file_index == current_index
        )[0].position;
        this.anamite_move({
          left: x - 100 / this.scale,
          top: y - 100 / this.scale,
        });
      }
    } catch (error) {
      console.error(error);
    }
  },
  clickXiaotiTitle: function({
    dati_id,
    xiaoti_id,
    xiaoti_num,
    dati_num,
    fileIndexes,
  }) {
    if (!fileIndexes || fileIndexes.size == 0) {
      return;
    }
    if (!fileIndexes.has(this.current_index)) {
      this.current_index = Array.from(fileIndexes).sort()[0];
      this.initBackground();
    }
    const c_xiaoti = this.actual_struct[dati_id].xiaotis[xiaoti_id];
    let hasThirdLevel = this.answer_list[dati_num].xiaotis[xiaoti_num]
      .hasMaterial;
    let autoFlag =  c_xiaoti.auto
    if (this.data.questions && this.data.questions.questionManual&&this.data.questions.questionManual.length){
      const title_num = dati_num+'.'+xiaoti_num
      const flag = this.data.questions.questionManual.indexOf(title_num) != -1
      autoFlag = flag?0: c_xiaoti.auto
    }
    this.current_question = {
      dati_id,
      dati_num,
      xiaoti_id,
      xiaoti_num,
      level: 2,
      score_position: c_xiaoti.score_position,
      is_datika: c_xiaoti.is_datika ? "1" : "0",
      autoFlag:autoFlag,
      hasThirdLevel,
    };
    this.init_question_exhibition_2();
    this.clear_except_background();
    const xiaoti = this.actual_struct[dati_id].xiaotis[xiaoti_id];
    this.fucker_render_one_xiaoti({ ...xiaoti, dati_num, dati_id });
    try {
      if (this.move_image_after_click) {
        const current_index = this.current_index;
        const [x, y] = xiaoti.areas.filter(
          (item) => item.file_index == current_index
        )[0].position;
        this.anamite_move({
          left: x - 100 / this.scale,
          top: y - 100 / this.scale,
        });
      }
    } catch (error) {
      console.error(error);
    }
  },
  clickThirdXiaotiTitle: function({
    dati_id,
    xiaoti_id,
    xiaoti_num,
    dati_num,
    third_xiaoti_id,
    third_xiaoti_num,
    fileIndexes,
  }) {
    if (!fileIndexes || fileIndexes.size == 0) {
      return;
    }
    if (!fileIndexes.has(this.current_index)) {
      this.current_index = Array.from(fileIndexes).sort()[0];
      this.initBackground();
    }
    this.current_question = {
      dati_id,
      dati_num,
      xiaoti_id,
      xiaoti_num,
      level: 3,
      third_xiaoti_id,
      third_xiaoti_num,
      hasThirdLevel: true,
    };
    this.init_question_exhibition_2();
    this.clear_except_background();
    const xiaoti = this.actual_struct[dati_id].xiaotis[xiaoti_id].xiaotis[
      third_xiaoti_id
    ];
    this.fucker_render_third_xiaoti({
      third_xiaoti: xiaoti,
      dati_id,
      xiaoti_id,
      xiaoti_num,
      dati_num,
      third_xiaoti_id,
      third_xiaoti_num,
    });
    try {
      if (this.move_image_after_click) {
        const [x, y] = xiaoti.areas[0].position;
        this.anamite_move({
          left: x - 100 / this.scale,
          top: y - 100 / this.scale,
        });
      }
    } catch (error) {
      console.error(error);
    }
  },
  clickAnswer: function({
    answer_index,
    dati_id,
    xiaoti_id,
    dati_num,
    xiaoti_num,
    third_xiaoti_id,
    third_xiaoti_num,
    fileIndexes,
  }) {
    if (!fileIndexes || fileIndexes.size == 0) {
      return;
    }
    if (!fileIndexes.has(this.current_index)) {
      this.current_index = Array.from(fileIndexes)[0];
      this.initBackground();
    }

    const c_xiaoti = this.actual_struct[dati_id].xiaotis[xiaoti_id];
    this.current_question = {
      level: 100,
      answer_index,
      dati_id,
      xiaoti_id,
      dati_num,
      xiaoti_num,
      third_xiaoti_id,
      third_xiaoti_num,
      hasThirdLevel: third_xiaoti_id,
      is_datika: c_xiaoti.is_datika ? "1" : "0",
    };
    this.init_question_exhibition_2();
    this.clear_except_background();
    let xiaoti = undefined;
    if (!third_xiaoti_id) {
      xiaoti = this.actual_struct[dati_id].xiaotis[xiaoti_id];
    } else {
      xiaoti = this.actual_struct[dati_id].xiaotis[xiaoti_id].xiaotis[
        third_xiaoti_id
      ];
    }
    const current_index = this.current_index;
    xiaoti.areas.forEach((item, index) => {
      if (item.file_index != current_index) {
        return;
      }
      this.group.add(
        this.generate_rectangle(
          { position: item.position, style: { stroke: "#F1592A" } },
          {
            level: 2,
            dati_num: dati_num,
            dati_id,
            xiaoti_num,
            area_index: index,
            xiaoti_id,
          }
        )
      );
    });
    const ap = xiaoti.answer_positions[answer_index - 1];
    this.group.add(
      this.generate_rectangle(
        { position: ap.position, style: { stroke: "green" }, resizable: true },
        {
          level: 100,
          dati_id,
          xiaoti_id,
          dati_num,
          xiaoti_num,
          answer_index,
          area_index: 0,
          answer_id: ap.id,
        }
      )
    );
    if (
      ap.tuka_positions != null &&
      ap.tuka_positions != undefined &&
      ap.tuka_positions.length > 0
    ) {
      ap.tuka_positions.forEach((item, index) => {
        this.group.add(
          this.generate_rectangle(
            {
              position: item,
              style: { stroke: "red", fill: "red", opacity: 0.3 },
            },
            {
              level: 101,
              dati_num: dati_num,
              dati_id,
              xiaoti_num,
              area_index: index,
              xiaoti_id,
              answer_index,
              tuka_index: index + 1,
            }
          )
        );
      });
    }
  },
  clicktuka: function({ tuka_index }) {
    this.current_question = { ...this.current_question, tuka_index };
  },
  render_all_datis: function(not_clear) {
    // 画所有已知的大题
    if (!not_clear) {
      this.clear_except_background();
    }
    const current_struct = this.actual_struct;
    for (let key in current_struct) {
      this.fucker_render_one_dati(current_struct[key]);
    }
  },
  ensureAddform: function() {
    //添加一般题目的确定按钮回调
    const self = this;
    this.$refs["addForm"].validate((valid) => {
      if (valid) {
        self.ensureAdd();
      } else {
        return false;
      }
    });
  },

  ensureAdd: function() {
    const { x, y, width, height } = this.add_tmp_element.opts.shape;

    if (this.add_level == 1) {
      const dati_id = find_dati_id(this.addForm.dati_num, this.actual_struct);
      const n_data_id = dati_id
        ? dati_id
        : this.addForm.dati_num + "-" + uuidv1();
      this.add_tmp_element.extra_info = {
        level: 1,
        dati_num: this.addForm.dati_num,
        dati_id: n_data_id,
      };
      if (!dati_id) {
        this.actual_struct[n_data_id] = {
          id: n_data_id,
          dati_num: parseInt(this.addForm.dati_num),
          areas: [
            {
              position: [x, y, x + width, y + height],
              file_index: this.current_index,
              bar_index: 0,
              image_file: "",
            },
          ],
          xiaotis: {},
        };
      } else {
        this.actual_struct[n_data_id].areas.push({
          position: [x, y, x + width, y + height],
          file_index: this.current_index,
          bar_index: 0,
          image_file: "",
        });
      }
      this.clear_except_background();
      this.init_question_exhibition_2();
      this.fucker_render_one_dati(this.actual_struct[n_data_id]);
    } else if (this.add_level == 2) {
      const { dati_id } = this.current_question;
      const xiaoti_id = find_xiaoti_id(
        this.addForm.xiaoti_num,
        this.actual_struct[dati_id].xiaotis
      );
      const n_xiaoti_id = this.addForm.xiaoti_num + "-" + uuidv1();
      if (!xiaoti_id) {
        this.actual_struct[dati_id].xiaotis[n_xiaoti_id] = {
          id: n_xiaoti_id,
          xiaoti_num: parseInt(this.addForm.xiaoti_num),
          areas: [
            {
              id: uuidv1(),
              position: [x, y, x + width, y + height],
              file_index: this.current_index,
              bar_index: 0,
              image_file: "",
            },
          ],
          answer_positions: [],
          pointer_positions: [],
        };
      } else {
        this.actual_struct[dati_id].xiaotis[xiaoti_id].areas.push({
          id: uuidv1(),
          position: [x, y, x + width, y + height],
          file_index: this.current_index,
          bar_index: 0,
          image_file: "",
        });
      }
      this.init_question_exhibition_2();
      this.clear_except_background();
      this.fucker_render_one_dati(this.actual_struct[dati_id]);
    } else if (this.add_level == 3) {
      const { dati_id, xiaoti_id } = this.current_question;

      const third_xiaoti_id = find_xiaoti_id(
        this.addForm.third_xiaoti_num,
        this.actual_struct[dati_id].xiaotis[xiaoti_id].xiaotis
      );
      if(!third_xiaoti_id){
              const n_xiaoti_id = this.addForm.third_xiaoti_num + "-" + uuidv1();
              if (!this.actual_struct[dati_id].xiaotis[xiaoti_id].xiaotis) {
                this.actual_struct[dati_id].xiaotis[xiaoti_id].xiaotis = {};
              }
              this.actual_struct[dati_id].xiaotis[xiaoti_id].xiaotis[n_xiaoti_id] = {
                id: n_xiaoti_id,
                xiaoti_num: parseInt(this.addForm.third_xiaoti_num),
                areas: [
                  {
                    id: uuidv1(),
                    position: [x, y, x + width, y + height],
                    file_index: this.current_index,
                    bar_index: 0,
                    image_file: "",
                  },
                ],
                answer_positions: [],
                pointer_positions: [],
              };

      }else{
        this.actual_struct[dati_id].xiaotis[xiaoti_id].xiaotis[third_xiaoti_id].areas.push({
          id: uuidv1(),
          position: [x, y, x + width, y + height],
          file_index: this.current_index,
          bar_index: 0,
          image_file: "",
        })
      }
      this.init_question_exhibition_2();
      this.clear_except_background();
      this.fucker_render_one_dati(this.actual_struct[dati_id]);
    } else if (this.add_level == 100) {
      const { dati_id, xiaoti_id, third_xiaoti_id } = this.current_question;
      let xiaoti = undefined;
      if (!third_xiaoti_id) {
        this.actual_struct[dati_id].xiaotis[xiaoti_id].answer_positions.splice(
          this.addForm.answer_index - 1,
          0,
          {
            id: uuidv1(),
            position: [x, y, x + width, y + height, 1],
            file_index: this.current_index,
            bar_index: 0,
            image_file: "",
            tuka_positions: [],
            zone_type: this.addForm.zone_type,
          }
        );
        xiaoti = this.actual_struct[dati_id].xiaotis[xiaoti_id];
      } else {
        this.actual_struct[dati_id].xiaotis[xiaoti_id].xiaotis[
          third_xiaoti_id
        ].answer_positions.splice(this.addForm.answer_index - 1, 0, {
          id: uuidv1(),
          position: [x, y, x + width, y + height, 1],
          file_index: this.current_index,
          bar_index: 0,
          image_file: "",
          tuka_positions: [],
          zone_type: this.addForm.zone_type,
        });
        xiaoti = this.actual_struct[dati_id].xiaotis[xiaoti_id].xiaotis[
          third_xiaoti_id
        ];
      }
      this.init_question_exhibition_2();
      this.clear_except_background();
      this.fucker_render_one_dati(this.actual_struct[dati_id]);
    }
    this.compare_questions();
    this.addFormVisible = false;
  },
};
