// let config = {
//     "test": {
//         host: 'http://47.97.207.223:9940',
//         ahost: "http://121.199.78.158:8080",
//         rd: "http://t.m.exue.site/"
//     },
//     "prod": {
//         // host: "http://47.97.176.254:9940",
//         host: "http://223.112.16.90:9940",
//         ahost: "http://47.111.155.195:8099",
//         rd: "http://m.exue.site:3334/"
//     },
//     "dev": {
//         host: 'http://localhost:9940',
//         ahost: "http://192.168.1.163:8099",
//         rd: ""
//     },
//     "test2":{
//         host:"http://116.62.156.212:9940",
//         ahost:"http://47.98.59.51:8080",
//         rd: ""
//     }
// };   //这里是一个默认的url，可以没有
let config = {};
switch (process.env.NODE_ENV) {
  case "test":
    config = {
      // host: 'http://192.168.1.153:9940',//陈丰
      host: "http://47.97.207.223:9940",
      ahost: "http://121.199.78.158:8080",
      rd: "http://t.m.exue.site/",
    };
    break;
  case "production": // 注意这里的名字要和步骤二中设置的环境名字对应起来
    config = {
      host: "http://36.155.91.116:9940",
      ahost: "https://mg.exuewang.com",
      // ahost: "http://47.111.155.195:8099",
      rd: "https://mg.exuewang.com/",
    };
    break;
  case "pre": // 注意这里的名字要和步骤二中设置的环境名字对应起来
    config = {
      host: "http://36.155.91.116:9940",
      ahost: "https://mg.exuewang.com",
      rd: "http://pre.mg.exuewang.com:3334/",
    };
    break;
  case "dev":
    config = {
      host: "http://localhost:9940",
      ahost: "http://192.168.1.163:8099",
      rd: "",
    };
    break;
  case "test2":
    config = {
      host: "http://223.112.16.90:9940",
      ahost: "http://36.137.74.53:8099",
      rd: "http:t2.m.exue.site:88",
    };
}

export default config;
